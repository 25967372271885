<template>
  <div class="infinite-canvas-wrapper" 
       @wheel.prevent="handleZoom"
       @mousedown.left="startPan"
       @mousemove="pan"
       @mouseup.left="stopPan"
       @mouseleave="stopPan">
    <div class="department-menu">
      <h3>
        Departments
        <button class="add-department-btn" @click="$emit('navigate', 'DepartmentsContent')">
          +
        </button>
      </h3>
      <ul>
        <li v-for="dept in departments" :key="dept.id">
          <button 
            class="department-btn"
            :class="{ active: selectedDepartmentId === dept.id }"
            @click="handleDepartmentSelect(dept.id)"
          >
            {{ dept.name }}
          </button>
        </li>
      </ul>
      <button class="add-process-btn" @click="addProcess">Add a Process</button>
    </div>
    <div class="infinite-canvas-container" :style="transformStyle">
      <div class="grid-visualization" v-if="showGrid">
        <div 
          v-for="x in gridLines.x" 
          :key="'x' + x"
          class="grid-line vertical"
          :style="{
            left: `${x * gridSize}px`,
            height: `${gridLines.height}px`,
            top: `${-gridLines.height/4}px`
          }"
        ></div>
        <div 
          v-for="y in gridLines.y" 
          :key="'y' + y"
          class="grid-line horizontal"
          :style="{
            top: `${y * gridSize}px`,
            width: `${gridLines.width}px`,
            left: `${-gridLines.width/4}px`
          }"
        ></div>
      </div>
      <div class="occupied-cells-layer">
        <div
          v-for="cell in occupiedCells"
          :key="`cell-${cell.x}-${cell.y}`"
          class="grid-cell-occupied"
          :style="{
            left: `${cell.x * gridSize}px`,
            top: `${cell.y * gridSize}px`,
            width: `${gridSize}px`,
            height: `${gridSize}px`
          }"
        ></div>
      </div>
      <OriginMarker 
        v-if="isLayoutReady"
        :panX="panX"
        :panY="panY"
        :scale="scale"
        @position-update="updateMarkerPosition"
      />
      <div v-for="process in departmentProcesses" 
           :key="process.id"
           class="process-container">
        <SwimLane 
          :process-id="process.id"
          :process-name="process.name"
          :scale="scale"
          :initial-grid-position="process.gridPosition"
          @scale-change="handleScaleChange"
          @show-steps-list="handleShowStepsListModal"
          @delete-process="handleDeleteProcess"
          @node-selection-request="handleNodeSelectionRequest"
          @node-delete-request="handleNodeDelete"
          @department-modal-request="handleDepartmentModalRequest"
          :ref="`swimlane-${process.id}`"
        />
      </div>
    </div>
    <MicrophoneControl />
    <div class="zoom-controls">
      <div class="zoom-percentage">{{ Math.round(scale * 100) }}%</div>
      <div class="zoom-buttons">
        <button @click="zoomIn">+</button>
        <button @click="zoomOut">-</button>
        <button @click="resetZoom">Reset</button>
      </div>
    </div>
    <ChatDialog />

    <!-- Process Naming Modal -->
    <div v-if="showProcessModal" class="process-modal-overlay">
      <div class="process-modal">
        <div class="modal-header">
          <h3>Create New Process</h3>
          <button class="close-btn" @click="cancelProcessName">&times;</button>
        </div>
        <div class="modal-body">
          <div class="input-group">
            <label for="process-name">Process Name</label>
            <input 
              id="process-name"
              v-model="processName" 
              type="text" 
              placeholder="Enter a descriptive name for your process"
              @keyup.enter="submitProcessName"
              ref="processNameInput"
            >
          </div>
        </div>
        <div class="modal-footer">
          <button @click="cancelProcessName" class="cancel-btn">Cancel</button>
          <button 
            @click="submitProcessName" 
            class="submit-btn"
            :disabled="!processName.trim()"
          >
            Create Process
          </button>
        </div>
      </div>
    </div>

    <StepsListModal 
      v-if="showStepsListModal"
      :nodes="currentNodes"
      :connections="currentConnections"
      :process-id="processId"
      @description-updated="handleDescriptionUpdate"
      @close="showStepsListModal = false"
    />

    <!-- Delete Process Modal -->
    <div v-if="showDeleteProcessModal" class="modal-overlay-centered">
      <div class="modal-centered">
        <h3>Delete Process</h3>
        <p>Are you sure you want to delete this process? This action cannot be undone.</p>
        <div class="modal-buttons">
          <button @click="cancelDeleteProcess" class="cancel-btn">Cancel</button>
          <button @click="confirmDeleteProcess" class="delete-btn">Delete</button>
        </div>
      </div>
    </div>

    <!-- Node Selection Modal -->
    <NodeSelectionModal
      v-if="showNodeSelectionModal"
      :nodes="currentNodes"
      :source-node-id="nodeSelectionSourceId"
      :mode="nodeSelectionMode"
      :process-id="currentProcessId"
      @select="handleNodeConnectionSelect"
      @close="closeNodeSelectionModal"
    />

    <!-- Node Selection Drawer -->
    <InfiniteCanvasDrawer
      :is-open="showNodeSelector"
      title="Select Node Type"
      @close="closeNodeSelector"
    >
      <template v-if="isGridNodeSelector">
        <!-- Show only basic node types for grid plus button -->
        <button class="node-type-btn" @click="handleNodeSelection('activity')">
          <span class="icon">⚙️</span>
          Activity
        </button>
        <button class="node-type-btn" @click="handleNodeSelection('decision')">
          <span class="icon">❓</span>
          Decision
        </button>
        <button class="node-type-btn" @click="handleNodeSelection('finish')">
          <span class="icon">🏁</span>
          Set as Finish
        </button>
      </template>
      <template v-else-if="!isFinishNodeSource">
        <button class="node-type-btn" @click="handleNodeSelection('activity')">
          <span class="icon">⚙️</span>
          Activity
        </button>
        <button class="node-type-btn" @click="handleNodeSelection('decision')">
          <span class="icon">❓</span>
          Decision
        </button>
        <button v-if="!isStartNodeSelector" class="node-type-btn" @click="handleNodeSelection('finish')">
          <span class="icon">🏁</span>
          Set as Finish
        </button>
        <button v-if="!isStartNodeSelector && !isYesNoNodeSelector && !isGridNodeSelector && !isFromPlusButton" class="node-type-btn" @click="handleNodeSelection('connect-exit')">
          <span class="icon">➡️</span>
          Connect Exit
        </button>
      </template>
      <button v-if="!isGridNodeSelector && !isStartNodeSelector && !isYesNoNodeSelector" class="node-type-btn" @click="handleNodeSelection('connect-entrance')">
        <span class="icon">⬅️</span>
        Connect Entrance
      </button>
    </InfiniteCanvasDrawer>

    <DeleteNodeModal 
      v-if="showDeleteNodeModal"
      :show="true"
      :type="deleteNodeModalType"
      @close="closeDeleteNodeModal"
      @confirm="confirmDeleteNode"
    />

    <!-- Department Modal -->
    <DepartmentModal
      v-if="showDepartmentModal"
      @close="closeDepartmentModal"
      @submit="handleNewDepartment"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import ChatDialog from './ChatDialog.vue'
import SwimLane from '@/views/SwimLane.vue'
import StepsListModal from '@/components/StepsListModal.vue'
import OriginMarker from '@/components/OriginMarker.vue'
import InfiniteCanvasDrawer from '@/components/InfiniteCanvasDrawer.vue'
import DeleteNodeModal from '@/components/DeleteNodeModal.vue'
import DepartmentModal from '@/components/DepartmentModal.vue'
import DepartmentOverlayModal from '@/components/DepartmentOverlayModal.vue'
import api, { updateProcessData } from '@/services/api'
import NodeSelectionModal from '@/components/NodeSelectionModal.vue'
import MicrophoneControl from './MicrophoneControl.vue'

export default {
  name: 'InfiniteCanvas',
  components: {
    ChatDialog,
    SwimLane,
    StepsListModal,
    OriginMarker,
    InfiniteCanvasDrawer,
    DeleteNodeModal,
    DepartmentModal,
    DepartmentOverlayModal,
    NodeSelectionModal,
    MicrophoneControl
  },
  props: {
    initialScale: {
      type: Number,
      default: 0.35
    }
  },
  data() {
    return {
      scale: this.initialScale,
      minScale: 0.1,
      maxScale: 3,
      panX: 0,
      panY: 0,
      isPanning: false,
      lastX: 0,
      lastY: 0,
      zoomStep: 0.1,
      showProcessModal: false,
      processName: '',
      currentNodes: [],
      currentConnections: [],
      isInitialLoad: true,
      markerPosition: { x: 0, y: 0 },
      showGrid: true,
      gridLines: {
        x: [],
        y: [],
        width: 0,
        height: 0
      },
      isLayoutReady: false,
      isComponentMounted: false,
      showDeleteProcessModal: false,
      processToDelete: null,
      isGridNodeSelector: false,
      isStartNodeSelector: false,
      isYesNoNodeSelector: false,
      isFinishNodeSource: false,
      isFromPlusButton: false,
      currentProcessId: null,
      currentSourceId: null,
      selectedGridPosition: null,
      showDeleteNodeModal: false,
      deleteNodeModalType: 'Activity',
      deleteNodeData: null,
      showDepartmentModal: false,
      departmentData: null,
      showNodeSelectionModal: false,
      nodeSelectionMode: null,
      nodeSelectionSourceId: null,
    }
  },
  computed: {
    ...mapState('departments', ['departments', 'selectedDepartmentId']),
    ...mapGetters('departments', ['getSelectedDepartment']),
    ...mapGetters('swimlane', ['getDepartmentProcesses']),
    ...mapState('grid', ['gridSize']),
    ...mapGetters('grid', ['isPositionOccupied', 'getAllOccupiedPositions']),
    ...mapGetters('modal', ['isModalOpen']),
    
    departmentProcesses() {
      // console.log('[InfiniteCanvas] Computing departmentProcesses:', {
      //   selectedDepartmentId: this.selectedDepartmentId,
      //   departments: this.departments,
      //   selectedDepartment: this.getSelectedDepartment
      // })
      const processes = this.getDepartmentProcesses(this.selectedDepartmentId)
      // console.log('[InfiniteCanvas] Got processes for department:', processes)
      return processes
    },
    
    occupiedCells() {
      const cells = this.getAllOccupiedPositions
      // console.log('[InfiniteCanvas] Occupied cells:', cells)
      return cells
    },
    
    transformStyle() {
      return {
        transform: `translate(${this.panX}px, ${this.panY}px) scale(${this.scale})`,
        transformOrigin: '0 0'
      }
    },

    showNodeSelector() {
      return this.isModalOpen('node-selector')
    },

    showStepsListModal() {
      return this.isModalOpen('steps-list')
    },
  },
  watch: {
    departmentProcesses: {
      handler(processes) {
        if (processes.length > 0 && this.isComponentMounted) {
          this.initializeLayout()
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('departments', ['createDepartment', 'selectDepartment', 'fetchUserDepartments']),
    ...mapActions('grid', ['updateProcessGrid', 'findAvailablePosition']),
    ...mapActions('modal', ['openModal', 'closeModal']),

    async handleDepartmentSelect(departmentId) {
      await this.selectDepartment(departmentId)
      this.centerContent()
    },

    handleScaleChange(newScale) {
      // console.log('[InfiniteCanvas] Scale changed:', newScale)
      this.$emit('scale-change', newScale)
    },

    handleZoom(event) {
      event.preventDefault()
      const delta = -event.deltaY
      
      const wrapper = event.currentTarget
      const rect = wrapper.getBoundingClientRect()
      const mouseX = event.clientX - rect.left
      const mouseY = event.clientY - rect.top

      const newScale = delta > 0 
        ? Math.min(this.scale * 1.1, this.maxScale)
        : Math.max(this.scale * 0.9, this.minScale)

      if (newScale !== this.scale) {
        const mouseXInContent = (mouseX - this.panX) / this.scale
        const mouseYInContent = (mouseY - this.panY) / this.scale

        this.panX = mouseX - mouseXInContent * newScale
        this.panY = mouseY - mouseYInContent * newScale
        this.scale = newScale
        this.handleScaleChange(newScale)
      }
    },

    zoomIn() {
      const wrapper = this.$el
      const rect = wrapper.getBoundingClientRect()
      const centerX = rect.width / 2
      const centerY = rect.height / 2
      
      const newScale = Math.min(this.scale * 1.1, this.maxScale)
      if (newScale !== this.scale) {
        const centerXInContent = (centerX - this.panX) / this.scale
        const centerYInContent = (centerY - this.panY) / this.scale
        
        this.panX = centerX - centerXInContent * newScale
        this.panY = centerY - centerYInContent * newScale
        this.scale = newScale
      }
    },

    zoomOut() {
      const wrapper = this.$el
      const rect = wrapper.getBoundingClientRect()
      const centerX = rect.width / 2
      const centerY = rect.height / 2
      
      const newScale = Math.max(this.scale * 0.9, this.minScale)
      if (newScale !== this.scale) {
        const centerXInContent = (centerX - this.panX) / this.scale
        const centerYInContent = (centerY - this.panY) / this.scale
        
        this.panX = centerX - centerXInContent * newScale
        this.panY = centerY - centerYInContent * newScale
        this.scale = newScale
      }
    },

    resetZoom() {
      this.scale = 0.5
      this.$nextTick(() => {
        this.centerContent()
      })
    },

    startPan(event) {
      // Don't start panning if clicking on swimlane, controls, or grid area
      if (event.target.closest('.swimlane-container') || 
          event.target.closest('.department-menu') || 
          event.target.closest('.zoom-controls') ||
          event.target.closest('.grid-container')) {
        return
      }

      this.isPanning = true
      this.lastX = event.clientX
      this.lastY = event.clientY
      event.target.style.cursor = 'grabbing'
    },

    pan(event) {
      if (!this.isPanning) return

      // Add a multiplier to make the movement more pronounced
      const multiplier = 1
      const deltaX = (event.clientX - this.lastX) * multiplier
      const deltaY = (event.clientY - this.lastY) * multiplier

      this.panX += deltaX
      this.panY += deltaY

      this.lastX = event.clientX
      this.lastY = event.clientY
    },

    stopPan(event) {
      if (this.isPanning) {
        this.isPanning = false
        if (event && event.target) {
          event.target.style.cursor = 'grab'
        }
      }
    },

    centerContent() {
      const wrapper = this.$el
      if (!wrapper) return
      
      const wrapperRect = wrapper.getBoundingClientRect()
      const visibleWidth = wrapperRect.width
      const visibleHeight = wrapperRect.height
      
      // Simply center on grid coordinate 0,0
      this.panX = (visibleWidth / 2) - 300 // Account for sidebar
      this.panY = visibleHeight / 2
      
      // console.log('[InfiniteCanvas] Centered on grid origin:', {
      //   panX: this.panX,
      //   panY: this.panY
      // })
    },

    addProcess() {
      const selectedDept = this.departments.find(dept => dept.id === this.selectedDepartmentId)
      // console.log('Selected Department:', selectedDept)
      this.showProcessModal = true
      this.$nextTick(() => {
        this.$refs.processNameInput?.focus()
      })
    },

    async submitProcessName() {
      if (this.processName.trim()) {
        // Grid cell dimensions (matching swimlane.js)
        const cellWidth = 264
        const cellHeight = 250
        const horizontalGap = 100
        const columnWidth = cellWidth + horizontalGap
        const nodeWidth = 200
        const nodeHeight = 100

        // Calculate grid-aligned positions
        const startX = (cellWidth - nodeWidth) / 2  // Center in first column
        const step1X = columnWidth + (cellWidth - nodeWidth) / 2  // Center in second column
        const startY = (cellHeight - nodeHeight) / 2  // Center vertically in first row

        // console.log('Creating process at marker position:', this.markerPosition)

        // Use the exact grid coordinates from the marker
        const gridX = this.markerPosition.x
        const gridY = this.markerPosition.y

        const processData = {
          name: this.processName.trim(),
          gridPosition: {
            x: gridX,
            y: gridY
          },
          gridSize: { width: 1, height: 1 }, // Initial size in grid units
          flowData: {
            nodes: [
              {
                id: 'node1',
                title: 'Start',
                position: { x: startX, y: startY },
                type: 'start'
              },
              {
                id: 'node2',
                title: 'Step 1',
                position: { x: step1X, y: startY },
                type: 'activity'
              }
            ],
            connections: [
              {
                id: 'conn1',
                source: 'node1',
                target: 'node2'
              }
            ]
          },
          swimDepartments: [
            { id: 'customer', name: 'CUSTOMER', roles: [], color: this.$store.state.swimlane.colorScheme.backgrounds[0] },
            { id: 'sales', name: 'SALES', roles: [], color: this.$store.state.swimlane.colorScheme.backgrounds[1] },
            { id: 'stocks', name: 'STOCKS', roles: [], color: this.$store.state.swimlane.colorScheme.backgrounds[2] },
            { id: 'finance', name: 'FINANCE', roles: [], color: this.$store.state.swimlane.colorScheme.backgrounds[3] }
          ]
        }
        
        const processId = await this.$store.dispatch('swimlane/createProcess', {
          departmentId: this.selectedDepartmentId,
          processData
        })

        this.showProcessModal = false
        this.processName = ''
      }
    },

    cancelProcessName() {
      this.showProcessModal = false
      this.processName = ''
    },

    async handleShowStepsListModal(data) {
      this.currentNodes = data.nodes;
      this.currentConnections = data.connections;
      this.processId = data.processId;
      await this.openModal('steps-list');
    },

    updateMarkerPosition(position) {
      this.markerPosition = position
    },

    updateGridLines() {
      const container = this.$el.querySelector('.infinite-canvas-container')
      if (!container) return

      // Create a much larger grid that extends beyond the viewport
      const gridExtent = 50 // Number of cells to extend in each direction
      
      this.gridLines = {
        x: Array.from({ length: gridExtent * 2 }, (_, i) => i - gridExtent), // From -50 to +50
        y: Array.from({ length: gridExtent * 2 }, (_, i) => i - gridExtent), // From -50 to +50
        width: this.gridSize * gridExtent * 2,  // Total width of grid
        height: this.gridSize * gridExtent * 2  // Total height of grid
      }
    },

    initializeLayout() {
      // Only initialize once
      if (this.isLayoutReady) return

      // Initial grid setup
      this.updateGridLines()
      
      // Pre-calculate the final pan values
      const wrapper = this.$el
      const wrapperRect = wrapper.getBoundingClientRect()
      const finalPanX = (wrapperRect.width / 2) - 300
      const finalPanY = wrapperRect.height / 2
      
      // Set the pan values directly
      this.panX = finalPanX
      this.panY = finalPanY
      
      // Wait for the next frame to ensure pan values are applied
      requestAnimationFrame(() => {
        // Verify the values are stable
        if (this.panX === finalPanX && this.panY === finalPanY) {
          // console.log('Marker initialized with pan values:', { x: finalPanX, y: finalPanY })
          // Wait for next tick to ensure all computed properties are updated
          this.$nextTick(() => {
            this.isLayoutReady = true
          })
        }
      })
    },

    handleDeleteProcess(processId) {
      this.processToDelete = processId;
      this.showDeleteProcessModal = true;
    },

    async confirmDeleteProcess() {
      try {
        await api.deleteProcess(this.processToDelete);
        
        // Remove from grid store
        this.$store.commit('grid/REMOVE_SWIMLANE', this.processToDelete);
        
        // Remove from swimlane store
        this.$store.commit('swimlane/REMOVE_PROCESS', this.processToDelete);
        
        // Refresh the processes list
        await this.$store.dispatch('swimlane/fetchDepartmentProcesses', this.selectedDepartmentId);
        
        this.showDeleteProcessModal = false;
        this.processToDelete = null;
      } catch (error) {
        console.error('Error deleting process:', error);
        // You might want to show an error message to the user here
      }
    },

    cancelDeleteProcess() {
      this.showDeleteProcessModal = false;
      this.processToDelete = null;
    },

    async handleNodeSelectionRequest(data) {
      // console.log('Node selection request:', data)
      this.currentProcessId = data.processId
      this.currentSourceId = data.sourceId
      await this.openModal('node-selector')
      this.isGridNodeSelector = data.nodeType === 'grid'
      this.isStartNodeSelector = data.title === 'Start'
      this.isYesNoNodeSelector = data.title === 'Yes' || data.title === 'No'
      this.isFinishNodeSource = data.nodeType === 'finish'
      this.isFromPlusButton = !this.isGridNodeSelector
      this.selectedGridPosition = data.gridPosition
    },

    handleNodeSelection(nodeType) {
      // console.log('Node type selected:', nodeType)
      // console.log('HERE Current process ID:', this.currentProcessId)
      
      if (nodeType === 'connect-entrance' || nodeType === 'connect-exit') {
        // Show the node selection modal
        this.nodeSelectionMode = nodeType === 'connect-entrance' ? 'entrance' : 'exit'
        this.nodeSelectionSourceId = this.currentSourceId
        // console.log('Setting source node ID:', this.currentSourceId)
        
        // Get the flow data from the store
        const flowData = this.$store.getters['swimlane/getProcessFlowData'](this.currentProcessId)
        // console.log('Getting flow data for process:', this.currentProcessId, flowData)
        
        // Set the nodes for the modal
        this.currentNodes = flowData.nodes
        this.showNodeSelectionModal = true
      } else if (this.isGridNodeSelector) {
        // console.log('Adding grid node with position:', this.selectedGridPosition)
        const swimlaneComponent = this.$refs[`swimlane-${this.currentProcessId}`]
        if (swimlaneComponent && swimlaneComponent[0]) {
          swimlaneComponent[0].addNode(nodeType, this.selectedGridPosition)
        }
        this.closeNodeSelector()
      } else {
        const sourceId = this.currentSourceId // Get the source node ID
        // console.log('Adding regular node with source:', sourceId)
        const swimlaneComponent = this.$refs[`swimlane-${this.currentProcessId}`]
        if (swimlaneComponent && swimlaneComponent[0]) {
          swimlaneComponent[0].addNode(nodeType, null, sourceId)
        }
        this.closeNodeSelector()
      }
    },

    resetNodeSelectorState() {
      this.isGridNodeSelector = false
      this.isStartNodeSelector = false
      this.isYesNoNodeSelector = false
      this.isFinishNodeSource = false
      this.isFromPlusButton = false
      this.selectedGridPosition = null
      // Only reset process and source IDs if we're not showing the node selection modal
      if (!this.showNodeSelectionModal) {
        this.currentProcessId = null
        this.currentSourceId = null
      }
    },

    closeNodeSelector() {
      this.closeModal()
      this.resetNodeSelectorState()
    },

    handleNodeDelete(data) {
      // console.log('InfiniteCanvas: handleNodeDelete called with data:', data);
      this.deleteNodeData = data;
      this.deleteNodeModalType = data.type.charAt(0).toUpperCase() + data.type.slice(1);
      this.showDeleteNodeModal = true;
    },

    closeDeleteNodeModal() {
      // console.log('InfiniteCanvas: closeDeleteNodeModal called');
      this.showDeleteNodeModal = false;
      this.deleteNodeData = null;
    },

    async confirmDeleteNode() {
      // console.log('InfiniteCanvas: confirmDeleteNode called');
      if (!this.deleteNodeData) {
        console.error('No node data available for deletion');
        return;
      }

      // Store the data locally before any async operations
      const { processId, nodeId, type, departmentId, departmentIndex } = this.deleteNodeData;
      // console.log('Deleting node with data:', { processId, nodeId, type, departmentId, departmentIndex });

      try {
        if (type === 'Role') {
          // Get the process directly from the store
          const process = this.$store.state.swimlane.processes[processId];
          if (!process) {
            throw new Error('Process not found in store');
          }

          // Update the process in the store and database
          await this.$store.dispatch('swimlane/removeRole', {
            swimlaneId: processId,
            departmentId,
            roleId: nodeId
          });
        } else if (type === 'Department') {
          // Get the process directly from the store
          const process = this.$store.state.swimlane.processes[processId];
          if (!process) {
            throw new Error('Process not found in store');
          }

          // Update the process in the store and database
          await this.$store.dispatch('swimlane/removeDepartment', {
            processId,
            departmentId,
            departmentIndex
          });
        } else {
          // Existing node deletion logic...
          const swimlaneComponent = this.$refs[`swimlane-${processId}`];
          if (!swimlaneComponent || !swimlaneComponent[0]) {
            throw new Error('Swimlane component not found');
          }
          
          swimlaneComponent[0].handleRemoveNode(nodeId);
          
          const updatedFlowData = {
            nodes: swimlaneComponent[0].nodes,
            connections: swimlaneComponent[0].connections.map(conn => ({
              source: conn.source,
              target: conn.target,
              sourceHandle: conn.sourceHandle,
              targetHandle: conn.targetHandle
            }))
          };
          
          // console.log('Deleting node with updated flow data:', updatedFlowData);
          await updateProcessData(processId, { flowData: updatedFlowData });
        }

        // Close the modal after successful update
        this.closeDeleteNodeModal();
      } catch (error) {
        console.error('Error deleting node:', error);
        // You might want to show an error message to the user here
      }
    },

    handleDepartmentModalRequest(data) {
      // console.log('Opening department modal with data:', data);
      this.departmentData = data;
      this.showDepartmentModal = true;
    },

    closeDepartmentModal() {
      this.showDepartmentModal = false;
      this.departmentData = null;
    },

    async handleNewDepartment(name) {
      if (this.departmentData) {
        // This is for swimlane departments
        const swimlaneComponent = this.$refs[`swimlane-${this.departmentData.processId}`];
        if (swimlaneComponent && swimlaneComponent[0]) {
          swimlaneComponent[0].departmentData = this.departmentData;
          swimlaneComponent[0].handleNewDepartment(name);
        }
      } else {
        // This is for overlay departments
        try {
          const newDepartment = await this.createDepartment({ name: name.toUpperCase() })
          // Select the newly created department
          await this.$store.dispatch('departments/selectDepartment', newDepartment.id)
          this.$emit('update:selectedDepartment', newDepartment.id)
        } catch (error) {
          console.error('Error creating department:', error)
        }
      }
      this.closeDepartmentModal();
    },

    addDepartment() {
      // This is for the overlay plus button
      this.departmentData = null; // Ensure we're using the overlay modal
      this.showDepartmentModal = true;
    },

    closeNodeSelectionModal() {
      this.showNodeSelectionModal = false;
      this.nodeSelectionMode = null;
      // Don't clear nodeSelectionSourceId here as it's needed for the connection
    },

    async handleNodeConnectionSelect(node) {
      // console.log('🚀 [InfiniteCanvas] Starting node connection process:', {
      //   sourceId: this.nodeSelectionSourceId,
      //   targetNode: node,
      //   mode: this.nodeSelectionMode,
      //   currentProcessId: this.currentProcessId
      // })

      // Get the swimlane component
      const swimlaneComponent = this.$refs[`swimlane-${this.currentProcessId}`]
      if (!swimlaneComponent || !swimlaneComponent[0]) {
        console.error('❌ [InfiniteCanvas] Swimlane component not found')
        return
      }

      // Create local copies of nodes and connections
      let localNodes = [...swimlaneComponent[0].nodes]
      let localConnections = [...swimlaneComponent[0].connections]
      // console.log('📝 [InfiniteCanvas] Created local copies:', {
      //   nodesCount: localNodes.length,
      //   connectionsCount: localConnections.length
      // })

      const getNextConnId = () => {
        const connIds = localConnections.map(c => {
          const match = c.id?.match(/\d+/)
          return match ? parseInt(match[0]) : 0
        })
        return Math.max(0, ...connIds) + 1
      }

      const sourceNode = localNodes.find(n => n.id === this.nodeSelectionSourceId)
      const targetNode = localNodes.find(n => n.id === node.id)
      const startNode = localNodes.find(n => n.title === 'Start')

      // console.log('🎯 [InfiniteCanvas] Found nodes:', {
      //   sourceNode: sourceNode ? { id: sourceNode.id, type: sourceNode.type } : null,
      //   targetNode: targetNode ? { id: targetNode.id, type: targetNode.type } : null,
      //   startNode: startNode ? { id: startNode.id, type: startNode.type } : null
      // })

      // When connecting TO a finish node, always treat it as an entrance connection
      if (targetNode.type === 'finish') {
        // console.log('🏁 [InfiniteCanvas] Handling finish node connection')
        // Add connection
        const connection = {
          id: `conn${getNextConnId()}`,
          source: sourceNode.id,
          target: targetNode.id
        }
        localConnections.push(connection)
        
        // Update the finish node's inputs array
        const finishNode = localNodes.find(n => n.id === targetNode.id)
        if (finishNode) {
          if (!finishNode.connections) {
            finishNode.connections = { inputs: [], outputs: [] }
          }
          if (!finishNode.connections.inputs.includes(sourceNode.id)) {
            finishNode.connections.inputs.push(sourceNode.id)
          }
        }
      }

      // For all other connections, proceed with normal connection logic
      if (this.nodeSelectionMode === 'exit') {
        // console.log('🏁 [InfiniteCanvas] Handling exit connection')
        // Find any node that connects to the target (previous node in chain)
        const targetEntranceConnection = localConnections.find(
          c => c.target === targetNode.id
        )

        // Find any node that the target connects to (next node in chain)
        const targetExitConnection = localConnections.find(
          c => c.source === targetNode.id
        )

        // Find Start node's connection if it exists
        const startConnection = startNode ? localConnections.find(
          c => c.source === startNode.id
        ) : null

        // Remove connections but preserve Start connection
        localConnections = localConnections.filter(c =>
          (startConnection && c.id === startConnection.id) ||
          (c.source !== targetNode.id && c.target !== targetNode.id)
        )

        // Connect source to target
        localConnections.push({
          id: `conn${getNextConnId()}`,
          source: sourceNode.id,
          target: targetNode.id
        })

        // If target had an outgoing connection, connect source to that node
        if (targetExitConnection) {
          localConnections.push({
            id: `conn${getNextConnId()}`,
            source: targetNode.id,
            target: targetExitConnection.target
          })
        }
      } else { // entrance connection
        // console.log('⬅️ [InfiniteCanvas] Handling entrance connection')
        
        // Log all connections for debugging
        // console.log('🔍 [InfiniteCanvas] All connections:', localConnections.map(c => ({
        //   id: c.id,
        //   source: c.source,
        //   target: c.target,
        //   type: c.type
        // })))

        // Find any node that connects to the source (previous node in chain)
        const sourceExitConnection = localConnections.find(
          c => c.source === sourceNode.id || c.target === sourceNode.id
        )
        // console.log('🔍 [InfiniteCanvas] Found source exit connection:', sourceExitConnection)

        // Find any node that the target connects to (next node in chain)
        const targetExitConnection = localConnections.find(
          c => c.source === targetNode.id || c.target === targetNode.id
        )
        // console.log('🔍 [InfiniteCanvas] Found target exit connection:', targetExitConnection)

        // Find Start node's connection if it exists
        const startConnection = startNode ? localConnections.find(
          c => c.source === startNode.id
        ) : null
        // console.log('🔍 [InfiniteCanvas] Found start connection:', startConnection)

        // Find and preserve decision node connections
        const decisionConnections = localConnections.filter(conn => {
          const connSourceNode = localNodes.find(n => n.id === conn.source)
          const isDecisionConn = connSourceNode && 
                 connSourceNode.type === 'decision' && 
                 (!sourceNode || connSourceNode.id !== sourceNode.id)
          // console.log('🔍 [InfiniteCanvas] Checking decision connection:', {
          //   connection: conn,
          //   sourceNode: connSourceNode,
          //   isDecisionConn
          // })
          return isDecisionConn
        })
        // console.log('🔍 [InfiniteCanvas] Found decision connections:', decisionConnections)

        // Helper function to trace a branch path from a node
        const traceBranchPath = (nodeId) => {
          // console.log('🔍 [InfiniteCanvas] Tracing branch path from:', nodeId)
          const path = []
          let currentId = nodeId
          let visited = new Set()
          let depth = 0
          const maxDepth = 10 // Prevent infinite loops
          while (currentId && !visited.has(currentId) && depth < maxDepth) {
            visited.add(currentId)
            path.push(currentId)
            
            // Find next connection in the chain
            const nextConn = localConnections.find(c => 
              c.source === currentId && !c.type
            )
            // console.log('🔍 [InfiniteCanvas] Next connection in chain:', {
            //   currentId,
            //   nextConnection: nextConn,
            //   pathSoFar: [...path]
            // })
            currentId = nextConn ? nextConn.target : null
            depth++
          }
          return path
        }

        // Find all nodes in both yes and no branches
        const branchPaths = {}
        decisionConnections.forEach(conn => {
          if (conn.type === 'yes' || conn.type === 'no') {
            // console.log('🔍 [InfiniteCanvas] Tracing branch for connection:', conn)
            const path = traceBranchPath(conn.target)
            branchPaths[conn.type] = {
              decisionId: conn.source,
              path: path,
              startConnection: conn
            }
          }
        })
        // console.log('🔍 [InfiniteCanvas] Branch paths:', {
        //   paths: branchPaths,
        //   allConnections: localConnections
        // })

        // Check if either node is in a decision branch
        const findNodeBranch = (nodeId) => {
          for (const [branchType, pathInfo] of Object.entries(branchPaths)) {
            if (pathInfo.path.includes(nodeId)) {
              return {
                type: branchType,
                decisionId: pathInfo.decisionId,
                path: pathInfo.path
              }
            }
          }
          return null
        }

        const sourceBranchInfo = findNodeBranch(sourceNode.id)
        const targetBranchInfo = findNodeBranch(targetNode.id)
        // console.log('🌳 [InfiniteCanvas] Branch info:', {
        //   source: sourceBranchInfo,
        //   target: targetBranchInfo
        // })

        // If either node is in a branch, we need to preserve the branch structure
        if (sourceBranchInfo || targetBranchInfo) {
          // console.log('🌳 [InfiniteCanvas] Handling cross-branch connection')
          // Remove only the specific connections we need to replace
          const previousConnectionsCount = localConnections.length
          localConnections = localConnections.filter(c => {
            // Always keep decision node's direct Yes/No connections
            if (decisionConnections.some(dc => dc.id === c.id)) {
              // console.log('KEEPING DECISION CONNECTION:', c)
              return true
            }
            
            // Keep connections not involving our source node
            if (c.source !== sourceNode.id && c.target !== sourceNode.id) {
              // console.log('KEEPING UNINVOLVED CONNECTION:', c)
              return true
            }
            
            // console.log('REMOVING CONNECTION:', c)
            return false
          })

          // Add new connection from target to source
          const newConnection = {
            id: `conn${getNextConnId()}`,
            source: targetNode.id,
            target: sourceNode.id
          }
          localConnections.push(newConnection)
          // console.log('ADDED NEW CONNECTION:', newConnection)

          // Update branch types for all nodes in both branches
          const updateNodeBranchType = (nodeId, branchType) => {
            const node = localNodes.find(n => n.id === nodeId)
            if (node && node.type === 'activity' && !['Start'].includes(node.title)) {
              node.branchType = branchType
              // console.log('UPDATED NODE BRANCH TYPE:', {
              //   nodeId,
              //   title: node.title,
              //   branchType
              // })
            }
          }

          if (sourceBranchInfo) {
            sourceBranchInfo.path.forEach(nodeId => {
              updateNodeBranchType(nodeId, sourceBranchInfo.type)
            })
          }

          if (targetBranchInfo) {
            targetBranchInfo.path.forEach(nodeId => {
              updateNodeBranchType(nodeId, targetBranchInfo.type)
            })
          }

          // Update step names after the store is updated
          this.$nextTick(async () => {
            // console.log('CALLING UPDATE STEP NAMES')
            const swimlaneComponent = this.$refs[`swimlane-${this.currentProcessId}`]
            if (swimlaneComponent && swimlaneComponent[0]) {
              // console.log('SWIMLANE COMPONENT FOUND')
              // console.log('NODES BEFORE UPDATE:', localNodes.map(n => ({
              //   id: n.id,
              //   title: n.title,
              //   branchType: n.branchType
              // })))

              // First update the store with our current changes
              await this.$store.dispatch('swimlane/updateProcessFlowData', {
                processId: this.currentProcessId,
                flowData: {
                  nodes: localNodes,
                  connections: localConnections
                }
              })

              // Then let SwimLane handle the step naming
              // console.log('CALLING SWIMLANE UPDATE STEP NAMES METHOD')
              swimlaneComponent[0].updateStepNames()
              
              // Force update both components
              swimlaneComponent[0].$forceUpdate()
              this.$forceUpdate()
              
              // Get the updated nodes after SwimLane has processed them
              const updatedNodes = swimlaneComponent[0].nodes
              // console.log('NODES AFTER UPDATE:', updatedNodes.map(n => ({
              //   id: n.id,
              //   title: n.title,
              //   branchType: n.branchType
              // })))

              // Update the database with the final state
              await updateProcessData(this.currentProcessId, {
                flowData: {
                  nodes: updatedNodes,
                  connections: localConnections
                }
              })
            } else {
              console.log('SWIMLANE COMPONENT NOT FOUND')
            }
          })
        }
      }

      // Update the store with the final flow data
      // console.log('💾 [InfiniteCanvas] Updating store with final data:', {
      //   nodes: localNodes.length,
      //   connections: localConnections.length
      // })

      await this.$store.dispatch('swimlane/updateProcessFlowData', {
        processId: this.currentProcessId,
        flowData: {
          nodes: localNodes,
          connections: localConnections
        }
      })

      // When connecting TO a finish node, always treat it as an entrance connection
      if (targetNode.type === 'finish') {
        // console.log('🏁 [InfiniteCanvas] Handling finish node connection')
        // Add connection
        const connection = {
          id: `conn${getNextConnId()}`,
          source: sourceNode.id,
          target: targetNode.id
        }
        localConnections.push(connection)
        
        // Update the finish node's inputs array
        const finishNode = localNodes.find(n => n.id === targetNode.id)
        if (finishNode) {
          if (!finishNode.connections) {
            finishNode.connections = { inputs: [], outputs: [] }
          }
          if (!finishNode.connections.inputs.includes(sourceNode.id)) {
            finishNode.connections.inputs.push(sourceNode.id)
          }
        }
      }

      // Persist changes to the database
      try {
        // Get the swimlane component to ensure we have the latest state
        const swimlaneComponent = this.$refs[`swimlane-${this.currentProcessId}`]
        if (swimlaneComponent && swimlaneComponent[0]) {
          // Call updateStepNames to ensure proper step ordering
          swimlaneComponent[0].updateStepNames()
          
          // Get the final ordered connections from the swimlane
          const orderedConnections = swimlaneComponent[0].connections.map(conn => ({
            source: conn.source,
            target: conn.target,
            ...(conn.id && { id: conn.id })
          }))

          // console.log('💾 [InfiniteCanvas] Persisting to database:', {
          //   nodes: localNodes.length,
          //   connections: orderedConnections.length
          // })

          await updateProcessData(this.currentProcessId, {
            flowData: {
              nodes: localNodes,
              connections: orderedConnections
            }
          })
          console.log('✅ [InfiniteCanvas] Successfully persisted changes')
        }
      } catch (error) {
        console.error('❌ [InfiniteCanvas] Error saving flow data:', error)
      }

      // Update step names after the store is updated
      this.$nextTick(() => {
        // console.log('🔄 [InfiniteCanvas] Updating step names and forcing re-render')
        const swimlaneComponent = this.$refs[`swimlane-${this.currentProcessId}`]
        if (swimlaneComponent && swimlaneComponent[0]) {
          // Call updateStepNames directly without modifying computed properties
          swimlaneComponent[0].updateStepNames()
          
          // Force a re-render of the swimlane component
          this.$forceUpdate()
        }
      })

      this.closeNodeSelectionModal()
      console.log('🏁 [InfiniteCanvas] Connection process completed')
    },

    handleDescriptionUpdate({ nodeId, description }) {
      // Update the local node state if needed
      const nodeIndex = this.currentNodes.findIndex(n => n.id === nodeId);
      if (nodeIndex !== -1) {
        this.currentNodes[nodeIndex] = {
          ...this.currentNodes[nodeIndex],
          description
        };
      }
    },
  },

  beforeDestroy() {
    this.isPanning = false
    document.body.style.cursor = 'default'
  },

  async mounted() {
    this.isComponentMounted = true
    
    // Get user ID from assessment data
    const assessmentData = localStorage.getItem('assessmentData')
    // console.log('[InfiniteCanvas] Assessment data:', assessmentData)
    if (assessmentData) {
      const parsedData = JSON.parse(assessmentData)
      // console.log('[InfiniteCanvas] Parsed assessment data:', parsedData)
      if (parsedData.uid) {
        // console.log('[InfiniteCanvas] Fetching departments for uid:', parsedData.uid)
        await this.fetchUserDepartments(parsedData.uid)
        // console.log('[InfiniteCanvas] After fetching departments:', {
        //   departments: this.departments,
        //   selectedDepartmentId: this.selectedDepartmentId
        // })
      }
    }
    
    const processes = this.getDepartmentProcesses(this.selectedDepartmentId)
    // console.log('[InfiniteCanvas] Initial processes load:', {
    //   selectedDepartmentId: this.selectedDepartmentId,
    //   processes
    // })
    if (processes.length > 0) {
      this.initializeLayout()
    }
  }
}
</script>

<style scoped>
.infinite-canvas-wrapper {
  width: 100%;
  height: calc(100vh - 100px);
  overflow: hidden;
  position: relative;
  cursor: grab;
  background: var(--primary-background-cream);
  user-select: none;
  padding: 2rem;
}

.infinite-canvas-wrapper:active {
  cursor: grabbing;
}

.infinite-canvas-container {
  height: 100%;
  transform-origin: 0 0;
  will-change: transform;
  pointer-events: none;
  min-width: fit-content;
  display: inline-block;
}

.infinite-canvas-container > *,
.infinite-canvas-container .grid-container,
.infinite-canvas-container .flow-node {
  pointer-events: auto;
}

.zoom-controls {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  background: white;
  border-radius: 8px;
  padding: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.zoom-percentage {
  text-align: center;
  color: var(--main-text-color);
  font-size: 14px;
  margin-bottom: 4px;
}

.zoom-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.zoom-buttons button {
  width: 36px;
  height: 36px;
  margin: 0;
  border: none;
  border-radius: 4px;
  background: var(--accent-victory-green);
  color: var(--primary-background-cream);
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.zoom-buttons button:hover {
  background: var(--secondary-background-sky-blue);
}

.zoom-buttons button:last-child {
  width: auto;
  padding: 0 12px;
  font-size: 14px;
  height: 36px;
}

.department-menu {
  position: fixed;
  top: 0;
  z-index: 1000;
  background: var(--primary-background-cream);
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  display: flex;
  flex-direction: column;
}

.department-menu h3 {
  margin: 0 0 12px 0;
  color: var(--main-text-color);
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-department-btn {
  color: var(--accent-victory-green);
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s;
}

.add-department-btn:hover {
  color: var(--secondary-background-sky-blue);
}

.department-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.department-menu li {
  margin: 8px 0;
}

.department-btn {
  width: 100%;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background: var(--accent-victory-green);
  color: var(--primary-background-cream);
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
  text-align: left;
}

.department-btn.active {
  background-color: var(--secondary-background-sky-blue);
}

.department-btn:hover:not(.active) {
  background-color: var(--secondary-background-sky-blue);
  opacity: 0.8;
}

.infinite-canvas-container .grid-container {
  cursor: default;
}

.infinite-canvas-container .flow-node {
  cursor: grab;
}

.infinite-canvas-container .flow-node:active {
  cursor: grabbing;
}

.infinite-canvas-wrapper .grid-container {
  cursor: default !important;
}

.infinite-canvas-wrapper .flow-node {
  cursor: grab !important;
}

.infinite-canvas-wrapper .flow-node:active {
  cursor: grabbing !important;
}

.add-process-btn {
  width: 100%;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background: var(--accent-victory-green);
  color: var(--primary-background-cream);
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
  text-align: center;
  margin-top: 16px;
}

.add-process-btn:hover {
  background-color: var(--secondary-background-sky-blue);
}

.process-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  backdrop-filter: blur(4px);
}

.process-modal {
  background: white;
  width: 450px;
  border-radius: 12px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
  animation: modalSlideIn 0.3s ease-out;
  overflow: hidden;
}

@keyframes modalSlideIn {
  from {
    opacity: 0;
    transform: translateY(-20px) scale(0.95);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.modal-header {
  padding: 20px 24px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h3 {
  margin: 0;
  color: var(--main-text-color);
  font-size: 1.5rem;
  font-weight: 600;
}

.close-btn {
  background: none;
  border: none;
  font-size: 24px;
  color: #666;
  cursor: pointer;
  padding: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  transition: all 0.2s;
}

.close-btn:hover {
  background-color: #f5f5f5;
  color: #333;
}

.modal-body {
  padding: 24px;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input-group label {
  color: var(--main-text-color);
  font-weight: 500;
  font-size: 0.9rem;
}

.input-group input {
  padding: 12px 16px;
  border: 2px solid #eee;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s;
  width: 100%;
  box-sizing: border-box;
}

.input-group input:hover {
  border-color: #ddd;
}

.input-group input:focus {
  outline: none;
  border-color: var(--accent-victory-green);
  box-shadow: 0 0 0 3px rgba(0, 51, 37, 0.1);
}

.modal-footer {
  padding: 20px 24px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.modal-footer button {
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.modal-footer .cancel-btn {
  background: #f5f5f5;
  border: none;
  color: #666;
}

.modal-footer .cancel-btn:hover {
  background: #eee;
  color: #333;
}

.modal-footer .submit-btn {
  background: var(--accent-victory-green);
  border: none;
  color: white;
}

.modal-footer .submit-btn:hover:not(:disabled) {
  background: #0D4E36;
  transform: translateY(-1px);
}

.modal-footer .submit-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.process-container {
  margin-bottom: 2rem;
  position: relative;
  transform-origin: 0 0;
}

.grid-visualization {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.grid-line {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
}

.grid-line.vertical {
  width: 1px;
}

.grid-line.horizontal {
  height: 1px;
}

.occupied-cells-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9999;
}

.grid-cell-occupied {
  position: absolute;
  background-color: rgba(255, 0, 0, 0.3);
  border: 2px solid rgba(255, 0, 0, 0.5);
  pointer-events: none;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  /* justify-content: center;
  align-items: center; */
  z-index: 9999;
}

.modal {
  background: white;
  padding: 24px;
  border-radius: 8px;
  width: 400px;
  box-sizing: border-box;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  animation: modalFadeIn 0.3s ease-out;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-content {
  background: white;
  border-radius: 8px;
  width: 400px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 32px rgba(0, 0, 0, 0.15);
  animation: modalFadeIn 0.3s ease-out;
}

.modal h3 {
  margin-top: 0;
  color: var(--main-text-color);
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.cancel-btn {
  padding: 0.5rem 1rem;
  border: 1px solid var(--accent-victory-green);
  background: none;
  color: var(--accent-victory-green);
  border-radius: 4px;
  cursor: pointer;
}

.delete-btn {
  padding: 0.5rem 1rem;
  border: none;
  background-color: #dc3545;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-btn:hover {
  background-color: rgba(0, 51, 37, 0.1);
}

.delete-btn:hover {
  background-color: #bd2130;
}

.node-type-btn {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 1em;
  color: #212529;
}

.node-type-btn:hover {
  background-color: #f0f0f0;
}

.node-type-btn .icon {
  width: 28px;
  height: 28px;
  background: var(--accent-victory-green);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1em;
}

.modal-overlay-centered {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-centered {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 90%;
}

.modal-centered h3 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.modal-centered p {
  margin-bottom: 1.5rem;
}

.modal-centered .modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
</style> 