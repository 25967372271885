<template>
  <transition name="slide">
    <div v-if="isOpen" class="mobile-menu-container">
      <div class="mobile-menu-content">
        <div class="mobile-menu-header">
          <img src="@/assets/logo.png" alt="Victory AI Logo" class="mobile-logo" @click="navigateHome">
          <button class="close-menu" @click="$emit('close')">
            <span></span>
            <span></span>
          </button>
        </div>
        <div class="mobile-nav">
          <router-link to="/" class="nav-link" @click="$emit('close')">
            Home
          </router-link>
          <router-link to="/how-it-works" class="nav-link" @click="$emit('close')">
            How it Works
          </router-link>
          <router-link to="/why-victory" class="nav-link" @click="$emit('close')">
            Why Victory
          </router-link>
          <a href="#" class="nav-link" @click.prevent="handleWhitePaper">
            White Paper
          </a>
          <router-link to="/schedule" class="nav-link" @click="$emit('close')">
            Schedule Call
          </router-link>
          <router-link to="/assessment" class="nav-link" @click="$emit('close')">
            Take Assessment
          </router-link>
          <router-link to="/login" class="mobile-login-btn" @click="$emit('close')">
            Login
          </router-link>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'MobileMenu',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    handleWhitePaper() {
      this.$emit('show-white-paper')
      this.$emit('close')
    },
    navigateHome() {
      this.$router.push('/')
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.mobile-menu-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(90deg, #fcf8f2, #79cce1);
  z-index: 2000;
}

.mobile-menu-content {
  padding: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.mobile-menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  margin-bottom: 20px;
}

.mobile-logo {
  height: 40px;
  cursor: pointer;
}

.close-menu {
  width: 30px;
  height: 30px;
  position: relative;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.close-menu span {
  position: absolute;
  width: 100%;
  height: 3px;
  background: var(--accent-victory-green);
  left: 0;
}

.close-menu span:first-child {
  transform: rotate(45deg);
  top: 50%;
}

.close-menu span:last-child {
  transform: rotate(-45deg);
  top: 50%;
}

.mobile-nav {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px 0;
}

.nav-link {
  color: var(--accent-victory-green);
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 12px 0;
  border-bottom: 1px solid rgba(9, 57, 39, 0.1);
  transition: all 0.3s ease;
}

.nav-link:hover,
.nav-link.router-link-active {
  color: var(--accent-sunny-yellow);
  transform: translateX(10px);
}

.mobile-login-btn {
  background: var(--accent-victory-green);
  color: white !important;
  text-decoration: none;
  padding: 12px 24px;
  border-radius: 25px;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: 15px;
  transition: all 0.3s ease;
}

.mobile-login-btn:hover {
  background: var(--accent-sunny-yellow);
  color: var(--accent-victory-green) !important;
  transform: scale(1.05);
}

/* Slide transition */
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}

.slide-enter-to,
.slide-leave-from {
  transform: translateX(0);
}
</style> 