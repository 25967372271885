<template>
  <div>
    <Transition name="banner">
      <div v-if="!isBannerClosed" class="white-paper-banner">
        <div class="banner-content">
          <div class="banner-left">
            <span class="new-badge">NEW</span>
            <span class="banner-text">
              2025 Victory AI White Paper
            </span>
          </div>
          <div class="banner-right">
            <span class="banner-subtitle">
              How CTOs & Founders Plan to Use AI in 2025
            </span>
            <button class="banner-cta" @click="showModal = true">
              Read Now
            </button>
          </div>
          <button class="close-button" @click="closeBanner" aria-label="Close banner">
            ×
          </button>
        </div>
      </div>
    </Transition>
    
    <white-paper-modal 
      :show="showModal" 
      @close="showModal = false"
      @email-submitted="handleEmailSubmitted"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import WhitePaperModal from './WhitePaperModal.vue'

export default {
  name: 'WhitePaperBanner',
  components: {
    WhitePaperModal
  },
  data() {
    return {
      showModal: false
    }
  },
  computed: {
    ...mapState({
      isBannerClosed: state => state.whitePaperBannerClosed
    })
  },
  methods: {
    ...mapActions(['closeWhitePaperBanner']),
    closeBanner() {
      this.closeWhitePaperBanner()
      this.$emit('banner-closed')
    },
    async handleEmailSubmitted(email) {
      try {
        // Here you would typically make an API call to handle the email submission
        // console.log('Email submitted:', email)
        // You could also dispatch a Vuex action here to handle the email submission
        
        // Show success message or handle the response
      } catch (error) {
        console.error('Error submitting email:', error)
      }
    }
  }
}
</script>

<style scoped>
.white-paper-banner {
  background: var(--accent-victory-green);
  color: white;
  padding: 12px 20px;
  position: relative;
  z-index: 2000;
}

.banner-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  gap: 20px;
}

.banner-left, .banner-right {
  display: flex;
  align-items: center;
  gap: 16px;
}

.new-badge {
  background: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
}

.banner-text {
  font-size: 16px;
  font-weight: 600;
}

.banner-subtitle {
  color: rgba(255, 255, 255, 0.9);
  font-size: 14px;
}

.banner-cta {
  background: white;
  color: var(--accent-victory-green);
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.banner-cta:hover {
  background: var(--accent-sunny-yellow);
  transform: translateY(-1px);
}

.close-button {
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.8);
  font-size: 24px;
  cursor: pointer;
  padding: 0 8px;
  transition: color 0.2s ease;
  line-height: 1;
}

.close-button:hover {
  color: white;
}

/* Transition animations */
.banner-enter-active,
.banner-leave-active {
  transition: all 0.3s ease;
}

.banner-enter-from,
.banner-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

.banner-enter-to,
.banner-leave-from {
  transform: translateY(0);
  max-height: 100px;
}

@media (max-width: 768px) {
  .banner {
    padding: 8px 10px;
  }

  .banner-content {
    flex-direction: column;
    gap: 8px;
    padding: 5px 30px 5px 5px;
    text-align: center;
  }

  .banner-left, .banner-right {
    width: 100%;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
  }

  .new-badge {
    font-size: 12px;
    padding: 2px 6px;
  }

  .banner-text {
    font-size: 14px;
  }

  .banner-subtitle {
    font-size: 12px;
  }

  .banner-cta {
    width: 100%;
    padding: 6px 12px;
    font-size: 14px;
  }

  .close-button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 20px;
  }

  /* Adjust banner animations for mobile */
  .banner-enter-to,
  .banner-leave-from {
    max-height: 150px; /* Increase max height for mobile layout */
  }
}
</style> 