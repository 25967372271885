<template>
  <div class="why-victory-container" @wheel="handleScroll" @touchstart="handleTouchStart" @touchmove="handleTouchMove" @touchend="handleTouchEnd">
    <div class="fixed-header">Why Victory AI?</div>
    <div class="why-victory-content">
      <transition-group :name="transitionName" tag="div">
        <section v-for="(section, index) in sections" :key="index" 
                 class="why-victory-section" 
                 :id="'section-' + index"
                 v-show="index === currentSection">
          <div class="section-content">
            <h2>{{ section.title }}</h2>
            <div v-html="section.content"></div>
            <div v-if="index === 1" class="button-container">
              <button @click="getStarted" class="assessment-btn">Get Started</button>
            </div>
            <div v-if="index === 2" class="button-container">
              <button @click="getStarted" class="schedule-btn">Get Started</button>
            </div>
            <div v-if="index === 3" class="button-container">
              <button @click="getStarted" class="assessment-btn">Get Started</button>
            </div>
            <div v-if="index === sections.length - 1" class="button-container">
              <button @click="getStarted" class="schedule-btn">Get Started</button>
            </div>
          </div>
        </section>
      </transition-group>
    </div>
    <div class="progress-bar-container">
      <div class="progress-bar" :style="{ height: progress + '%' }"></div>
      <div v-for="(section, index) in sections" :key="index" 
           class="progress-node-container"
           :class="{ active: currentSection >= index }"
           :style="{ top: (index / (sections.length - 1)) * 100 + '%' }"
           @click="scrollToSection(index)">
        <div class="progress-node"></div>
        <span class="progress-label">{{ section.title }}</span>
      </div>
    </div>
    <div class="mobile-progress" v-if="isMobile">
      <div class="mobile-progress-text">
        <span class="current-section">{{ currentSection + 1 }}/{{ sections.length }}</span>
        <h3 class="current-title">{{ sections[currentSection].title }}</h3>
      </div>
      <div class="mobile-progress-bar-container">
        <div class="mobile-progress-bar" :style="{ width: progress + '%' }"></div>
      </div>
    </div>
    <div class="swipe-indicators" v-if="isMobile">
      <div class="swipe-up" v-if="currentSection < sections.length - 1">
        <span class="arrow">↓</span>
        <span class="swipe-text">Swipe Down</span>
      </div>
      <div class="swipe-down" v-if="currentSection > 0">
        <span class="swipe-text">Swipe Up</span>
        <span class="arrow">↑</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WhyVictoryView',
  data() {
    return {
      progress: 0,
      currentSection: 0,
      sections: [
        {
          title: 'Why Victory AI?',
          content: `<p>Victory AI helps businesses implement AI strategically and intentionally. We create a clear roadmap that identifies both quick wins and major opportunities, ensuring every AI investment delivers real value to your business.</p>`
        },
        {
          title: 'Free Assessment',
          content: `<p>Get started with our guided discovery process. We'll help you identify areas where AI can have the biggest impact in your organization and create a clear roadmap for implementation.</p>`
        },
        {
          title: 'Expert Guidance',
          content: `<p>We understand business and function at the edge of innovation in AI. We pride ourselves in simple, clear communication of complicated ideas. After completing our guided process, we'll help you understand your goals and show you how Victory AI can help future-proof your business with a guaranteed winning AI strategy.</p>`
        },
        {
          title: 'Flexible Options',
          content: `<p>Choose the scope that fits your needs. Whether you want to focus on a single project, transform an entire department, or revolutionize your whole organization, we have a solution that works for you.</p>`
        },
        {
          title: 'Clear Process',
          content: `<p>Our structured discovery process dives deep into your operations, identifying bottlenecks, opportunities, and areas where human expertise is most valuable. You'll receive detailed process diagrams, specific recommendations, and clear cost estimates.</p>`
        },
        {
          title: 'Implementation Freedom',
          content: `<p>After receiving your customized plan, you have complete flexibility. Build the solutions internally, work with a third party, or let Victory AI handle the implementation. Track everything through your personalized dashboard.</p>`
        },
        {
          title: 'Risk-Free Guarantee',
          content: `<p>Your success is our priority. That's why we offer a 100% money-back guarantee if you're not completely satisfied with our process within 7 days of receiving your plan. Start your AI transformation journey with confidence.</p>`
        },
        {
          title: 'Get Started Today',
          content: `<p>Begin your journey to AI success with two simple steps:</p>
            <p>1. Take our free AI assessment to understand your opportunities.</p>
            <p>2. Schedule a discovery call to discuss your results.</p>
            <p class="guarantee-text">🛡️ 100% Satisfaction Guaranteed</p>`
        }
      ],
      isScrolling: false,
      scrollTimeout: null,
      transitionName: 'slide-down',
      isMobile: false
    }
  },
  methods: {
    handleScroll(event) {
      event.preventDefault();
      
      if (this.isScrolling) return;
      
      this.isScrolling = true;
      clearTimeout(this.scrollTimeout);
      
      const direction = event.deltaY > 0 ? 1 : -1;
      const nextSection = Math.max(0, Math.min(this.sections.length - 1, this.currentSection + direction));
      
      if (nextSection !== this.currentSection) {
        this.transitionName = direction > 0 ? 'slide-up' : 'slide-down';
        this.currentSection = nextSection;
        this.updateProgress();
      }
      
      this.scrollTimeout = setTimeout(() => {
        this.isScrolling = false;
      }, 1000);
    },
    updateProgress() {
      this.progress = (this.currentSection / (this.sections.length - 1)) * 100;
    },
    scrollToSection(index) {
      this.currentSection = index;
      this.updateProgress();
    },
    getStarted() {
      this.$router.push('/get-started');
    },
    viewPricing() {
      this.$router.push('/pricing');
    },
    handleTouchStart(event) {
      if (event.target.tagName === 'BUTTON') {
        return;
      }
      
      if (this.isMobile) {
        this.touchStartY = event.touches[0].clientY;
      }
    },
    handleTouchMove(event) {
      if (event.target.tagName === 'BUTTON') {
        return;
      }
      
      if (this.isMobile) {
        event.preventDefault();
      }
    },
    handleTouchEnd(event) {
      if (event.target.tagName === 'BUTTON') {
        return;
      }
      
      if (this.isMobile) {
        const touchEndY = event.changedTouches[0].clientY;
        const touchDiff = this.touchStartY - touchEndY;
        
        const minSwipeDistance = 50;
        
        if (Math.abs(touchDiff) > minSwipeDistance) {
          if (touchDiff > 0 && this.currentSection < this.sections.length - 1) {
            this.transitionName = 'slide-up';
            this.currentSection++;
          } else if (touchDiff < 0 && this.currentSection > 0) {
            this.transitionName = 'slide-down';
            this.currentSection--;
          }
          this.updateProgress();
        }
      }
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    }
  },
  mounted() {
    this.updateProgress();
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
  }
}
</script>

<style scoped>
.why-victory-container {
  height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center;
  
}

.why-victory-content {
  height: 100vh;
  position: relative;
  width: 35%; 
  /* max-width: 1200px;  */
  /* margin: 0 auto; */
  margin-bottom: 15%;
  display: flex; /* Add flex display */
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */

  
}

.why-victory-section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center the content horizontally */
  overflow: hidden; /* Prevent scrolling within sections */
}

.section-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
  overflow: hidden; /* Prevent scrolling within content */
  max-height: 100vh; /* Limit height to viewport */
}

h2 {
  color: var(--accent-victory-green);
  font-size: 2.5rem;
  margin-bottom: 20px;
}

h3 {
  color: var(--accent-victory-green);
  font-size: 1.8rem;
  margin-bottom: 15px;
}

p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 15px;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

li {
  font-size: 1.1rem;
  margin-bottom: 10px;
  padding-left: 30px;
  position: relative;
}

li::before {
  content: '✓';
  color: var(--accent-victory-green);
  position: absolute;
  left: 0;
  top: 0;
}

.progress-bar-container {
  position: fixed;
  right: 30%; /* Adjust this value to move the progress bar closer to the content */
  /* top: 50%; */
  /* transform: translateY(-50%); */
  margin-bottom: 10%;
  height: 60vh;
  width: 2px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 1px;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--accent-victory-green);
  border-radius: 1px;
  transition: height 0.3s ease;
}

.progress-node-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.progress-node {
  width: 8px;
  height: 8px;
  background-color: var(--primary-background-cream);
  border: 2px solid var(--accent-victory-green);
  border-radius: 50%;
  transition: all 0.3s ease;
}

.progress-node-container.active .progress-node {
  background-color: var(--accent-victory-green);
}

.progress-label {
  position: absolute;
  left: 20px;
  color: #468847;
  font-weight: 600;
  font-size: 0.8rem;
  white-space: nowrap;
  transition: color 0.3s ease;
}

.progress-node-container:hover .progress-label,
.progress-node-container.active .progress-label {
  color: var(--accent-victory-green);
}

/* Updated Fade and Slide transition styles */
.fade-enter-active,
.fade-leave-active,
.fade-slide-up-enter-active,
.fade-slide-up-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
  transition-delay: 0.1s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-slide-up-enter-from {
  opacity: 0;
  transform: translateY(20px);
}

.fade-slide-up-leave-to {
  opacity: 0;
  /* transform: translateY(-20px); */
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
  transform: translateY(0);
}

/* Ensure the section-content has a relative positioning */
.section-content {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  transition: all 1.5s ease;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.8s ease;
}

.slide-fade-enter-from {
  transform: translateY(100%);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

.slide-up-enter-active,
.slide-up-leave-active,
.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 0.8s ease;
}

.slide-up-enter-from {
  transform: translateY(100%);
  opacity: 0;
}

.slide-up-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

.slide-down-enter-from {
  transform: translateY(-100%);
  opacity: 0;
}

.slide-down-leave-to {
  transform: translateY(100%);
  opacity: 0;
}

.button-container {
  display: flex;
  justify-content: left;
  gap: 20px;
  margin-top: 20px;
}

.schedule-btn,
.assessment-btn {
  background-color: var(--accent-victory-green);
  color: var(--primary-background-cream);
  border: none;
  padding: 12px 24px;
  font-size: 1.1rem;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.schedule-btn:hover,
.assessment-btn:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
  transform: scale(1.05);
}

.assessment-btn {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

.assessment-btn:hover {
  background-color: var(--accent-victory-green);
  color: var(--primary-background-cream);
}

@media (max-width: 768px) {
  .why-victory-container {
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
  }

  .why-victory-content {
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 20px;
    overflow: hidden;
  }

  .why-victory-section {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    touch-action: pan-y pinch-zoom;
  }

  .section-content {
    max-height: 80vh;
    overflow: hidden;
    padding: 20px;
    margin-top: -15vh;
    -webkit-overflow-scrolling: touch;
    position: relative;
    touch-action: pan-y pinch-zoom;
  }

  .progress-bar-container {
    display: none; /* Hide progress bar on mobile */
  }

  h2 {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
    text-align: left;
    padding-left: 15px;
  }

  h3 {
    font-size: 1.3rem;
    margin: 1rem 0;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1rem;
  }

  ul {
    padding-left: 20px;
    margin-bottom: 1rem;
  }

  li {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  .button-container {
    flex-direction: column;
    gap: 15px;
    padding: 20px 15px;
    width: 90%;
    margin: 0 auto;
  }

  .schedule-btn,
  .assessment-btn {
    width: 100%;
    padding: 15px;
    font-size: 1rem;
    text-align: center;
    margin: 0;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    touch-action: manipulation;
  }

  .mobile-progress {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.95);
    padding: 10px 15px;
    z-index: 100;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  }

  .mobile-progress-text {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 8px;
  }

  .current-section {
    font-size: 0.9rem;
    color: var(--accent-victory-green);
    font-weight: 600;
  }

  .current-title {
    font-size: 1rem;
    color: var(--accent-victory-green);
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mobile-progress-bar-container {
    width: 100%;
    height: 4px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    margin-bottom: 8px;
  }

  .mobile-progress-bar {
    height: 100%;
    background-color: var(--accent-victory-green);
    border-radius: 2px;
    transition: width 0.3s ease;
  }

  .why-victory-content {
    padding-top: 20px;
    padding-bottom: 70px;
  }

  .section-content {
    padding-bottom: 20px;
  }

  .swipe-indicators {
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 90;
    pointer-events: none;
    display: flex;
    justify-content: center;
    gap: 30px;
    bottom: 92px; /* Changed from 90px to 92px to move indicators up 2% */
  }

  .swipe-up, .swipe-down {
    position: static; /* Remove absolute positioning */
    display: flex;
    align-items: center;
    gap: 5px;
    animation: fadeInOut 2s infinite;
  }

  .arrow {
    display: inline; /* Change from block to inline */
    font-size: 24px;
    color: var(--accent-victory-green);
    margin: 0 5px;
  }

  .swipe-text {
    font-size: 12px;
    color: var(--accent-victory-green);
    opacity: 0.8;
  }

  /* Remove individual positioning styles */
  .swipe-up {
    display: flex;
    align-items: center;
  }

  .swipe-down {
    display: flex;
    align-items: center;
  }

  /* Keep the fade animation */
  @keyframes fadeInOut {
    0%, 100% { opacity: 0.4; }
    50% { opacity: 0.8; }
  }

  /* Adjust section content padding */
  .section-content {
    padding-top: 20px;
    /* padding-bottom: 100px; */
  }
}

.fixed-header {
  position: fixed;
  top: 20px;
  left: 40px;
  font-size: 2rem;
  color: var(--accent-victory-green);
  font-weight: bold;
  z-index: 100;
}

@media (max-width: 768px) {
  .fixed-header {
    top: 15px;
    left: 20px;
    font-size: 1.5rem;
  }
}

.getting-started-steps {
  margin: 20px 0;
}

.step {
  margin-bottom: 20px;
}

.step h3 {
  color: var(--accent-victory-green);
  font-size: 1.4rem;
  margin-bottom: 10px;
}

.guarantee-text {
  margin-top: 20px;
  font-style: italic;
  color: var(--accent-victory-green);
  font-weight: 500;
}

@media (max-width: 768px) {
  .step h3 {
    font-size: 1.2rem;
  }
  
  .guarantee-text {
    font-size: 0.9rem;
  }
}
</style>
