<template>
  <Transition name="modal">
    <div v-if="show" class="modal-backdrop" @click.self="close">
      <div class="modal-content">
        <button class="close-modal" @click="close">×</button>
        <div class="modal-header">
          <h2>2025 Victory AI White Paper</h2>
          <h3 class="subtitle">How CTOs & Founders Plan to Use AI in 2025</h3>
          <div class="report-details">
            <div class="detail-item">
              <span class="detail-icon">📊</span>
              <span>121 CTOs & Founders surveyed</span>
            </div>
            <div class="detail-item">
              <span class="detail-icon">📄</span>
              <span>5-page comprehensive report</span>
            </div>
          </div>
          <div class="highlights">
            <p>Get exclusive insights into:</p>
            <ul>
              <li>Market sentiment and AI adoption trends</li>
              <li>Priority areas for AI implementation</li>
              <li>Strategic recommendations</li>
              <li>Real-world use cases</li>
            </ul>
          </div>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitEmail">
            <div class="form-group">
              <input 
                type="email" 
                v-model="email" 
                placeholder="Your business email"
                required
                :class="{ 'error': emailError }"
              >
              <span class="error-message" v-if="emailError">{{ emailError }}</span>
            </div>
            <button 
              type="submit" 
              class="submit-btn"
              :disabled="isSubmitting"
            >
              {{ isSubmitting ? 'Sending...' : 'Get the Report' }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { submitWhitePaperEmail } from '../services/api';

export default {
  name: 'WhitePaperModal',
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      email: '',
      emailError: '',
      isSubmitting: false
    }
  },
  methods: {
    close() {
      this.$emit('close')
      this.email = ''
      this.emailError = ''
    },
    async submitEmail() {
      this.emailError = ''
      if (!this.validateEmail(this.email)) {
        this.emailError = 'Please enter a valid email address'
        return
      }

      this.isSubmitting = true
      try {
        const response = await submitWhitePaperEmail(this.email)
        
        // Open the PDF in a new tab with the updated link
        window.open('https://res.cloudinary.com/dhkjot9fq/image/upload/v1731932957/Victory_AI_-_White_Paper_11-18-24_dmvg9u.pdf', '_blank')
        
        // Emit the email to parent component
        this.$emit('email-submitted', this.email)
        
        // Close the modal
        this.close()
      } catch (error) {
        this.emailError = error.message || 'An error occurred. Please try again.'
      } finally {
        this.isSubmitting = false
      }
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return re.test(email)
    }
  }
}
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000;
  padding: 20px;
}

.modal-content {
  background: var(--primary-background-cream);
  padding: 40px;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  position: relative;
  margin: auto;
  transform: translateY(0);
}

.close-modal {
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: var(--accent-victory-green);
  padding: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.close-modal:hover {
  background: rgba(0, 0, 0, 0.1);
  transform: none;
}

.modal-header {
  text-align: center;
  margin-bottom: 30px;
}

.modal-header h2 {
  color: var(--accent-victory-green);
  margin-bottom: 8px;
  font-size: 1.8rem;
}

.subtitle {
  color: var(--accent-victory-green);
  font-size: 1.3rem;
  margin-bottom: 20px;
  font-weight: 600;
}

.report-details {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin: 20px 0;
}

.detail-item {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
}

.detail-icon {
  font-size: 1.2rem;
}

.highlights {
  background: rgba(9, 57, 39, 0.05);
  padding: 20px;
  border-radius: 8px;
  margin: 20px 0;
  text-align: left;
}

.highlights p {
  color: #666;
  margin-bottom: 10px;
  font-weight: 500;
}

.highlights ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.highlights li {
  padding: 8px 0;
  padding-left: 24px;
  position: relative;
  color: #666;
}

.highlights li::before {
  content: "✓";
  position: absolute;
  left: 0;
  color: var(--accent-victory-green);
  font-weight: bold;
}

.form-group {
  margin-bottom: 20px;
  width: 94%;
}

input {
  width: 100%;
  padding: 12px;
  border: 2px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.2s ease;
}

input:focus {
  outline: none;
  border-color: var(--accent-victory-green);
}

input.error {
  border-color: #ff4444;
}

.error-message {
  color: #ff4444;
  font-size: 0.9rem;
  margin-top: 5px;
  display: block;
}

.submit-btn {
  width: 100%;
  padding: 12px;
  background: var(--accent-victory-green);
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.submit-btn:hover {
  background: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

.submit-btn:disabled {
  background: #ccc;
  cursor: not-allowed;
  transform: none;
}

/* Update the modal transitions */
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.3s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.modal-enter-active .modal-content {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.modal-leave-active .modal-content {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.modal-enter-from .modal-content {
  transform: translateY(-20px);
  opacity: 0;
}

.modal-leave-to .modal-content {
  transform: translateY(20px);
  opacity: 0;
}

/* Add media query for mobile */
@media (max-width: 768px) {
  .modal-backdrop {
    padding: 0;
    align-items: flex-start;
    background: var(--primary-background-cream);
  }

  .modal-content {
    padding: 20px;
    width: 100%;
    height: 100vh;
    max-width: none;
    border-radius: 0;
    margin: 0;
    transform: none;
    overflow-y: auto;
  }

  .close-modal {
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    font-size: 28px;
  }

  .modal-header {
    margin-top: 40px;
  }

  .modal-header h2 {
    font-size: 1.4rem;
    margin-bottom: 10px;
  }

  .subtitle {
    font-size: 1rem;
    margin-bottom: 15px;
  }

  .report-details {
    flex-direction: column;
    gap: 10px;
    margin: 15px 0;
  }

  .detail-item {
    justify-content: center;
    font-size: 0.9rem;
  }

  .highlights {
    margin: 15px 0;
    padding: 15px;
  }

  .highlights li {
    font-size: 0.9rem;
    padding: 6px 0 6px 20px;
  }

  /* Adjust transitions for mobile */
  .modal-enter-from .modal-content,
  .modal-leave-to .modal-content {
    transform: translateY(0);
  }

  .modal-enter-active .modal-content,
  .modal-leave-active .modal-content {
    transition: opacity 0.3s ease;
  }

  .form-group {
    width: 93%;
  }
}
</style> 