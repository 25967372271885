<template>
  <div class="microphone-control">
    <button 
      class="mic-button" 
      :class="{ active: isListening }"
      @click="handleMicrophoneClick"
    >
      <i class="fas" :class="isListening ? 'fa-microphone-slash' : 'fa-microphone'"></i>
    </button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'MicrophoneControl',
  computed: {
    ...mapState({
      isListening: state => state.swimlaneMicrophone
    })
  },
  mounted() {
    // console.log('[MicrophoneControl] Component mounted')
    // Override getUserMedia for this component
    const originalGetUserMedia = navigator.mediaDevices.getUserMedia;
    this.getUserMedia = async function(constraints) {
      // console.log('[MicrophoneControl] getUserMedia called with:', constraints)
      try {
        return await originalGetUserMedia.call(navigator.mediaDevices, constraints);
      } catch (error) {
        // console.log('[MicrophoneControl] getUserMedia error:', error.name)
        if (error.name === 'NotFoundError') {
          // console.log('[MicrophoneControl] NotFoundError caught')
          // Let the global handler show the modal
          throw error;
        }
        throw error;
      }
    }.bind(this);
  },
  methods: {
    ...mapActions(['toggleSwimlaneMicrophone']),
    async checkMicrophoneAccess() {
      // console.log('[MicrophoneControl] Checking microphone access')
      try {
        const stream = await this.getUserMedia({ audio: true })
        // console.log('[MicrophoneControl] Got microphone stream:', stream)
        stream.getTracks().forEach(track => track.stop())
        return true
      } catch (err) {
        console.error('[MicrophoneControl] Microphone access error:', err)
        return false
      }
    },
    async handleMicrophoneClick() {
      // console.log('[MicrophoneControl] Microphone button clicked')
      const hasMicrophone = await this.checkMicrophoneAccess()
      // console.log('[MicrophoneControl] Has microphone:', hasMicrophone)
      if (hasMicrophone) {
        // console.log('[MicrophoneControl] Toggling microphone state')
        this.toggleSwimlaneMicrophone()
      }
    }
  }
}
</script>

<style scoped>
.microphone-control {
  position: fixed;
  bottom: 120px;
  right: 20px;
  z-index: 1000;
  background: white;
  border-radius: 8px;
  padding: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.mic-button {
  width: 36px;
  height: 36px;
  margin: 0;
  border: none;
  border-radius: 4px;
  background: var(--accent-victory-green);
  color: var(--primary-background-cream);
  font-size: 18px;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.mic-button:hover {
  background: var(--secondary-background-sky-blue);
}

.mic-button.active {
  background: #dc3545;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.4);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(220, 53, 69, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0);
  }
}
</style> 