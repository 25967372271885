<template>
  <div class="employees-content">
    <div v-if="loading" class="loading-state">
      <i class="fas fa-spinner fa-spin"></i>
      <p>Loading departments...</p>
    </div>

    <EmptyDepartmentsState
      v-else-if="departments.length === 0"
      @navigate="$emit('navigate', $event)"
    />

    <div v-else class="content-layout">
      <!-- Header Section -->
      <div class="header-section">
        <h2>Department Employees</h2>
        <p class="description">
          Add employees for each department. These individuals will participate in an interview 
          to help document their day to day processes which we'll use to identify opportunities for AI implementation.
        </p>
      </div>

      <div class="main-content">
        <!-- Top Row -->
        <div class="top-row">
          <h2>Department Employees</h2>
          <button class="add-employees-btn" @click="openAddEmployeesModal">
            <i class="fas fa-plus"></i>
            Add Employees
          </button>
        </div>

        <!-- Content Row -->
        <div class="content-row">
          <!-- Departments Column -->
          <div class="departments-column">
            <div class="departments-list">
              <div v-for="dept in departments" 
                   :key="dept.name"
                   :class="['department-item', { active: selectedDepartment?.name === dept.name }]"
                   @click="selectDepartment(dept)">
                <i :class="getDepartmentIcon(dept.name)"></i>
                <span>{{ dept.name }}</span>
              </div>
            </div>
          </div>

          <!-- Employees Column -->
          <div class="employees-column">
            <div class="employees-list" v-if="selectedDepartment">
              <div v-if="departmentEmployees.length === 0" class="no-employees">
                <p>No employees added yet.</p>
                <p>Click the button above to add employees.</p>
              </div>
              <div v-else class="employees-table">
                <div class="table-header">
                  <div class="col-name">Name</div>
                  <div class="col-role">Role</div>
                  <div class="col-email">Email</div>
                  <div class="col-date">Email Sent</div>
                  <div class="col-status">Status</div>
                  <div v-if="isAdminView" class="col-status-action">Update Status</div>
                  <div class="col-actions">Actions</div>
                </div>
                <div class="table-body">
                  <div v-for="employee in departmentEmployees" 
                       :key="employee.email" 
                       class="table-row">
                    <div class="col-name">{{ employee.name }}</div>
                    <div class="col-role">{{ employee.role }}</div>
                    <div class="col-email">{{ employee.email }}</div>
                    <div class="col-date">{{ employee.emailSentDate || 'Not sent' }}</div>
                    <div class="col-status" :class="employee.status">
                      {{ employee.status || 'Pending' }}
                    </div>
                    <div v-if="isAdminView" class="col-status-action">
                      <select 
                        v-model="employee.status"
                        @change="updateEmployeeStatus(employee)"
                        class="status-select"
                      >
                        <option value="pending">Pending</option>
                        <option value="completed">Completed</option>
                        <option value="in_progress">In Progress</option>
                      </select>
                    </div>
                    <div class="col-actions">
                      <button class="delete-btn" @click.stop="openDeleteModal(employee)">
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Employees Modal -->
    <div v-if="showModal" 
         class="modal-overlay"
         @click="closeModal">
      <div class="modal-content" @click.stop>
        <button class="close-modal" @click="closeModal">
          <i class="fas fa-times"></i>
        </button>
        <h2>Add Employees to {{ selectedDepartment?.name }}</h2>
        
        <div class="employees-form">
          <div v-for="(employee, index) in newEmployees" 
               :key="index" 
               class="employee-entry">
            <div class="form-row">
              <div class="form-group">
                <label>Name</label>
                <input type="text" 
                       v-model="employee.name" 
                       placeholder="Employee name">
              </div>
              <div class="form-group">
                <label>Role</label>
                <input type="text" 
                       v-model="employee.role" 
                       placeholder="Employee role">
              </div>
              <div class="form-group">
                <label>Email</label>
                <input type="email" 
                       v-model="employee.email" 
                       placeholder="Employee email">
              </div>
              <button class="remove-employee" 
                      @click="removeEmployee(index)"
                      v-if="newEmployees.length > 1">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>

          <div class="modal-actions">
            <button class="add-another-btn" @click="addAnotherEmployee">
              <i class="fas fa-plus"></i>
              Add Another Employee
            </button>
            <div class="action-buttons">
              <button class="cancel-btn" @click="closeModal">Cancel</button>
              <button class="save-btn" 
                      @click="saveEmployees"
                      :disabled="!isFormValid">
                Save Employees
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete Confirmation Modal -->
    <div v-if="showDeleteModal" 
         class="modal-overlay"
         @click="closeDeleteModal">
      <div class="modal-content delete-modal" @click.stop>
        <h2>Confirm Delete</h2>
        <p>Are you sure you want to delete {{ employeeToDelete?.name }}?</p>
        <p class="delete-warning">This action cannot be undone.</p>
        
        <div class="modal-actions">
          <button class="cancel-btn" @click="closeDeleteModal">Cancel</button>
          <button class="delete-confirm-btn" @click="confirmDelete">
            Delete Employee
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBusinessPlan, updateDepartmentEmployees, deleteEmployee, getManagerDepartment, updateManagerEmployees, deleteManagerEmployee, updateEmployeeStatus } from '@/services/api'
import EmptyDepartmentsState from '@/components/EmptyDepartmentsState.vue'

export default {
  name: 'EmployeesContent',
  components: {
    EmptyDepartmentsState
  },
  props: {
    isAdminView: {
      type: Boolean,
      default: false
    },
    clientUid: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      loading: true,
      departments: [],
      selectedDepartment: null,
      showModal: false,
      showDeleteModal: false,
      employeeToDelete: null,
      newEmployees: [
        { name: '', role: '', email: '' }
      ],
      departmentEmployees: [],
      isManagerDashboard: false
    }
  },
  computed: {
    isFormValid() {
      return this.newEmployees.every(emp => 
        emp.name.trim() && 
        emp.role.trim() && 
        emp.email.trim() && 
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emp.email)
      )
    }
  },
  methods: {
    getDepartmentIcon(name) {
      const icons = {
        'Sales': 'fas fa-chart-line',
        'Customer Service': 'fas fa-headset',
        'Marketing': 'fas fa-bullhorn',
        'Operations': 'fas fa-cogs',
        'Inventory Management': 'fas fa-boxes',
        'Finance': 'fas fa-calculator'
      }
      return icons[name] || 'fas fa-building'
    },
    async fetchDepartments() {
      try {
        this.loading = true
        
        if (this.isAdminView && this.clientUid) {
          // Admin viewing a client's departments
          const response = await getBusinessPlan(this.clientUid);
          if (response.success && response.plan) {
            this.departments = response.plan;
            if (this.departments.length > 0) {
              await this.selectDepartment(this.departments[0]);
            }
          }
        } else if (this.isManagerDashboard) {
          // Get the company_uid from localStorage
          const companyUid = localStorage.getItem('company_uid')
          
          if (!companyUid) {
            // Wait for a short time and try again, in case ManagerView is still fetching it
            await new Promise(resolve => setTimeout(resolve, 1000))
            const retryCompanyUid = localStorage.getItem('company_uid')
            
            if (!retryCompanyUid) {
              throw new Error('Company ID not found')
            }
          }
          
          // Get manager's department data
          const response = await getManagerDepartment(companyUid)
          
          if (response.success && response.department) {
            this.departments = [response.department]
            if (this.departments.length > 0) {
              await this.selectDepartment(response.department)
            }
          } else {
            throw new Error('No department data found')
          }
        } else {
          // Original business dashboard logic
          const assessmentData = localStorage.getItem('assessmentData')
          
          if (assessmentData) {
            const parsedData = JSON.parse(assessmentData)
            const uid = parsedData.assessment.uid
            
            if (uid) {
              const response = await getBusinessPlan(uid)
              
              if (response.success && response.plan) {
                this.departments = response.plan
                if (this.departments.length > 0) {
                  await this.selectDepartment(this.departments[0])
                }
              }
            }
          }
        }
      } catch (error) {
        console.error('[EmployeesContent] Error in fetchDepartments:', error)
        throw error
      } finally {
        this.loading = false
      }
    },
    async selectDepartment(department) {
      try {
        if (this.isManagerDashboard) {
          // For manager dashboard, just set the department and employees
          this.selectedDepartment = department
          this.departmentEmployees = department.employees || []
        } else {
          // Original business dashboard logic
          const assessmentData = localStorage.getItem('assessmentData')
          if (assessmentData) {
            const parsedData = JSON.parse(assessmentData)
            const uid = parsedData.assessment.uid
            
            if (uid) {
              const response = await getBusinessPlan(uid)
              if (response.success && response.plan) {
                const updatedDepartment = response.plan.find(dept => dept.name === department.name)
                if (updatedDepartment) {
                  this.selectedDepartment = updatedDepartment
                  const departmentIndex = this.departments.findIndex(dept => dept.name === department.name)
                  if (departmentIndex !== -1) {
                    this.departments[departmentIndex] = updatedDepartment
                  }
                  this.departmentEmployees = updatedDepartment.employees || []
                  return
                }
              }
            }
          }
          
          // Fallback to cached data if server request fails
          this.selectedDepartment = department
          this.departmentEmployees = department.employees || []
        }
      } catch (error) {
        console.error('Error refreshing department data:', error)
        // Fallback to cached data
        this.selectedDepartment = department
        this.departmentEmployees = department.employees || []
      }
    },
    openAddEmployeesModal() {
      this.newEmployees = [{ name: '', role: '', email: '' }]
      this.showModal = true
    },
    closeModal() {
      this.showModal = false
      this.newEmployees = [{ name: '', role: '', email: '' }]
    },
    addAnotherEmployee() {
      this.newEmployees.push({ name: '', role: '', email: '' })
    },
    removeEmployee(index) {
      this.newEmployees.splice(index, 1)
    },
    async saveEmployees() {
      if (!this.isFormValid) return

      try {
        if (this.isAdminView && this.clientUid) {
          // Admin adding employees for a client
          const response = await updateDepartmentEmployees(
            this.clientUid,
            this.selectedDepartment.name,
            this.newEmployees.map(emp => ({
              name: emp.name.trim(),
              role: emp.role.trim(),
              email: emp.email.trim(),
              status: 'pending',
              emailSentDate: 'sent'
            }))
          )

          if (response.success) {
            this.departmentEmployees = [
              ...this.departmentEmployees,
              ...this.newEmployees.map(emp => ({
                name: emp.name.trim(),
                role: emp.role.trim(),
                email: emp.email.trim(),
                status: 'pending',
                emailSentDate: 'sent'
              }))
            ]
            this.closeModal()
          } else {
            throw new Error(response.error || 'Failed to save employees')
          }
        } else if (this.isManagerDashboard) {
          // Get the company_uid for manager operations
          const companyUid = localStorage.getItem('company_uid')
          if (!companyUid) {
            throw new Error('Company ID not found')
          }

          const response = await updateManagerEmployees(
            companyUid,
            this.newEmployees
          )

          if (response.success) {
            // Add the new employees to the local state
            this.departmentEmployees = [
              ...this.departmentEmployees,
              ...this.newEmployees.map(emp => ({
                name: emp.name.trim(),
                role: emp.role.trim(),
                email: emp.email.trim(),
                status: 'pending',
                emailSentDate: 'sent'
              }))
            ]
            this.closeModal()
          } else {
            throw new Error(response.error || 'Failed to save employees')
          }
        } else {
          // Original business dashboard logic
          const assessmentData = localStorage.getItem('assessmentData')
          if (!assessmentData) {
            throw new Error('No assessment data found')
          }

          const parsedData = JSON.parse(assessmentData)
          const uid = parsedData.assessment.uid

          if (!uid) {
            throw new Error('User ID not found')
          }

          const response = await updateDepartmentEmployees(
            uid,
            this.selectedDepartment.name,
            this.newEmployees.map(emp => ({
              name: emp.name.trim(),
              role: emp.role.trim(),
              email: emp.email.trim(),
              status: 'pending',
              emailSentDate: 'sent'
            }))
          )

          if (response.success) {
            // Add the new employees to the local state
            this.departmentEmployees = [
              ...this.departmentEmployees,
              ...this.newEmployees.map(emp => ({
                name: emp.name.trim(),
                role: emp.role.trim(),
                email: emp.email.trim(),
                status: 'pending',
                emailSentDate: 'sent'
              }))
            ]
            this.closeModal()
          } else {
            throw new Error(response.error || 'Failed to save employees')
          }
        }
      } catch (error) {
        console.error('Error saving employees:', error)
        alert('Failed to save employees. Please try again.')
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })
    },
    openDeleteModal(employee) {
      this.employeeToDelete = employee
      this.showDeleteModal = true
    },
    closeDeleteModal() {
      this.showDeleteModal = false
      this.employeeToDelete = null
    },
    async confirmDelete() {
      try {
        if (this.isAdminView && this.clientUid) {
          // Admin deleting an employee for a client
          const response = await deleteEmployee(
            this.clientUid,
            this.selectedDepartment.name,
            this.employeeToDelete.email
          )

          if (response.success) {
            this.departmentEmployees = this.departmentEmployees.filter(
              emp => emp.email !== this.employeeToDelete.email
            )
            this.closeDeleteModal()
          } else {
            throw new Error(response.error || 'Failed to delete employee')
          }
        } else if (this.isManagerDashboard) {
          // Get the company_uid for manager operations
          const companyUid = localStorage.getItem('company_uid')
          if (!companyUid) {
            throw new Error('Company ID not found')
          }

          const response = await deleteManagerEmployee(
            companyUid,
            this.employeeToDelete.email
          )

          if (response.success) {
            // Remove the employee from the local state
            this.departmentEmployees = this.departmentEmployees.filter(
              emp => emp.email !== this.employeeToDelete.email
            )
            this.closeDeleteModal()
          } else {
            throw new Error(response.error || 'Failed to delete employee')
          }
        } else {
          // Original business dashboard logic
          const assessmentData = localStorage.getItem('assessmentData')
          if (!assessmentData) {
            throw new Error('No assessment data found')
          }

          const parsedData = JSON.parse(assessmentData)
          const uid = parsedData.assessment.uid

          if (!uid) {
            throw new Error('User ID not found')
          }

          const response = await deleteEmployee(
            uid,
            this.selectedDepartment.name,
            this.employeeToDelete.email
          )

          if (response.success) {
            // Remove the employee from the local state
            this.departmentEmployees = this.departmentEmployees.filter(
              emp => emp.email !== this.employeeToDelete.email
            )
            this.closeDeleteModal()
          } else {
            throw new Error(response.error || 'Failed to delete employee')
          }
        }
      } catch (error) {
        console.error('Error deleting employee:', error)
        alert('Failed to delete employee. Please try again.')
      }
    },
    async updateEmployeeStatus(employee) {
      try {
        const response = await updateEmployeeStatus(
          this.clientUid,
          this.selectedDepartment.name,
          employee.email,
          employee.status
        );

        if (!response.success) {
          throw new Error(response.error || 'Failed to update employee status');
        }

        // Status is already updated in the UI due to v-model
      } catch (error) {
        console.error('Error updating employee status:', error);
        alert('Failed to update employee status. Please try again.');
        // Revert the status change in case of error
        await this.fetchDepartments();
      }
    }
  },
  async created() {
    await this.fetchDepartments()
  }
}
</script>

<style scoped>
.employees-content {
  padding: 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 20px;
}

.content-layout {
  width: 100%;
  max-width: 1600px;
  background: white;
  padding: 3rem;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-section {
  text-align: left;
  margin-bottom: 2rem;
}

.header-section h2 {
  color: var(--accent-victory-green);
  margin-bottom: 1.5rem;
  font-size: 2.2rem;
}

.description {
  color: #666;
  line-height: 1.5;
  font-size: 1.2rem;
}

.main-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}

.content-row {
  display: flex;
  gap: 2rem;
  width: 100%;
}

.departments-column {
  min-width: 250px;
  border-right: 1px solid #e5e7eb;
}

.employees-column {
  flex: 1;
}

.add-employees-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #004225;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.add-employees-btn i {
  font-size: 0.875rem;
}

.add-employees-btn:hover {
  background-color: #003319;
}

/* Departments Column */
.departments-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.department-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.department-item i {
  font-size: 1.2rem;
  color: var(--accent-victory-green);
}

.department-item:hover {
  background: rgba(var(--accent-victory-green-rgb), 0.1);
}

.department-item.active {
  background: var(--accent-victory-green);
  color: var(--primary-background-cream);
}

.department-item.active i {
  color: var(--primary-background-cream);
}

/* Employees Column */
.employees-list {
  background: #fff;
  border-radius: 8px;
}

.employees-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 2rem;
}

.employees-header h3 {
  color: var(--main-text-color);
  font-size: 1.6rem;
  margin: 0;
}

/* Employees Table */
.employees-table {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #eee;
  display: flex;
  flex-direction: column;
  height: calc(6 * (1rem * 2 + 1.5em) + 3.5rem); /* 7 rows + header height */
}

.table-header {
  display: grid;
  grid-template-columns: 2fr 1.5fr 2fr 1.5fr 1fr 1.5fr 0.5fr;
  gap: 1rem;
  background: #f8f8f8;
  padding: 1rem;
  font-weight: bold;
  color: var(--main-text-color);
  border-bottom: 1px solid #eee;
}

.table-body {
  flex: 1;
  overflow-y: auto;
}

.table-row {
  display: grid;
  grid-template-columns: 2fr 1.5fr 2fr 1.5fr 1fr 1.5fr 0.5fr;
  gap: 1rem;
  padding: 1rem;
  border-bottom: 1px solid #eee;
  align-items: center;
  background: white;
}

.table-row:last-child {
  border-bottom: none;
}

.col-status {
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 500;
}

.col-status.completed {
  background: #e6f4ea;
  color: #1e7e34;
}

.col-status.pending {
  background: #fff3e0;
  color: #f57c00;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 12px;
  padding: 2rem;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.close-modal {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: #666;
}

.employee-entry {
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #eee;
}

.employee-entry:last-child {
  border-bottom: none;
}

.form-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  position: relative;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  font-weight: bold;
  color: var(--main-text-color);
}

.form-group input {
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
}

.remove-employee {
  position: absolute;
  right: -2rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #dc3545;
  cursor: pointer;
  padding: 0.5rem;
}

.modal-actions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
}

.add-another-btn {
  background: none;
  border: 1px dashed var(--accent-victory-green);
  color: var(--accent-victory-green);
  padding: 1rem;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

.add-another-btn:hover {
  background: rgba(var(--accent-victory-green-rgb), 0.1);
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}

.cancel-btn, .save-btn {
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cancel-btn {
  background: none;
  border: 1px solid #ddd;
  color: #666;
}

.save-btn {
  background: var(--accent-victory-green);
  border: none;
  color: white;
}

.save-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.save-btn:not(:disabled):hover {
  background: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

/* Loading and Empty States */
.loading-state, .empty-state {
  text-align: center;
  padding: 3rem;
  color: #666;
}

.loading-state i, .empty-state i {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--accent-victory-green);
}

.action-button {
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  background: var(--accent-victory-green);
  color: white;
  border: none;
  border-radius: 6px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.action-button:hover {
  background: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

.no-employees {
  text-align: center;
  padding: 3rem;
  color: #666;
}

.no-employees p {
  margin: 0.5rem 0;
}

.delete-btn {
  background: none;
  border: none;
  color: #dc3545;
  cursor: pointer;
  padding: 0.5rem;
  transition: all 0.3s ease;
}

.delete-btn:hover {
  color: #bd2130;
}

.delete-modal {
  max-width: 400px;
}

.delete-modal h2 {
  color: #dc3545;
}

.delete-warning {
  color: #666;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.delete-confirm-btn {
  background: #dc3545;
  border: none;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.delete-confirm-btn:hover {
  background: #bd2130;
}

.col-status-action {
  flex: 1;
  padding: 0 0.5rem;
}

.status-select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
}

.status-select:hover {
  border-color: var(--accent-victory-green);
}

/* Style status options */
.status-select option {
  padding: 0.5rem;
}

/* Additional status classes */
.col-status.in_progress {
  background: #fff3cd;
  color: #856404;
}

.col-status.completed {
  background: #d4edda;
  color: #155724;
}

.col-status.pending {
  background: #fff3e0;
  color: #f57c00;
}
</style> 