<template>
  <div class="sidebar">
    <div class="logo-container">
      <img src="@/assets/logo.png" alt="Victory AI Logo" class="logo">
    </div>
    <nav>
      <ul>
        <li>
          <a @click="navigate('EmployeesContent')" 
             :class="['nav-link', { 'active': currentComponent === 'EmployeesContent' }]">
            Employees
          </a>
        </li>
        <li>
          <a @click="navigate('DiscoveryCallContent')" 
             :class="['nav-link', { 'active': currentComponent === 'DiscoveryCallContent' }]">
            Schedule Call
          </a>
        </li>
        <li>
          <a @click="navigate('SupportContent')" 
             :class="['nav-link', { 'active': currentComponent === 'SupportContent' }]">
            Support
          </a>
        </li>
      </ul>
    </nav>
    <div class="logout-container">
      <button @click="logout" class="logout-button">Logout</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ManagerSidebar',
  props: {
    currentComponent: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions(['setShowHeader', 'setLoginState']),
    ...mapActions('chatStore', ['resetStore']),
    navigate(component) {
      this.$emit('navigate', component);
    },
    async logout() {
      try {
        // Save email for "Remember Me" functionality before clearing
        const rememberedEmail = localStorage.getItem('rememberedEmail');
        
        // Clear Vuex store state
        this.setLoginState(false);
        this.resetStore();
        this.$store.commit('CLEAR_STORE');
        this.$store.commit('CLEAR_USER');
        this.$store.commit('RESET_ANSWERS');
        this.$store.commit('SET_ASSESSMENT_ID', null);
        this.$store.commit('SET_MANAGER_ASSESSMENT', null);
        
        // Clear all localStorage
        localStorage.clear();
        
        // Restore remembered email if needed
        if (rememberedEmail) {
          localStorage.setItem('rememberedEmail', rememberedEmail);
        }

        // Clear session storage
        sessionStorage.clear();
        
        // Reset any other state
        this.$store.commit('SET_SHOW_HEADER', true);
        this.$store.commit('SET_CURRENT_SECTION', null);
        this.$store.commit('SET_CHAT_HISTORY', []);
        this.$store.commit('SET_CURRENT_CHAT', null);
        
        // Navigate to login page
        this.$router.push('/login');
      } catch (error) {
        console.error('Error during logout:', error);
      }
    }
  }
}
</script>

<style scoped>
.sidebar {
  width: 200px;
  background-color: #f4f4f4;
  height: 95vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 4px 0 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.logo-container {
  text-align: center;
  margin-bottom: 40px;
}

.logo {
  width: 180px;
  height: auto;
}

nav {
  flex-grow: 1;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  margin-bottom: 20px;
}

.nav-link {
  text-decoration: none;
  color: var(--accent-victory-green);
  cursor: pointer;
  font-size: 1.1em;
  font-weight: 600;
  transition: all 0.3s ease;
  display: block;
  padding: 12px 15px;
  border-radius: 8px;
}

.nav-link:hover, .nav-link.active {
  background-color: var(--secondary-background-sky-blue);
  color: white;
  transform: translateX(5px);
}

.logout-container {
  margin-top: auto;
  padding-top: 20px;
}

.logout-button {
  width: 100%;
  padding: 12px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1em;
  font-weight: 600;
  text-transform: uppercase;
}

.logout-button:hover {
  background-color: #c82333;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
    padding: 15px;
  }

  .logo-container {
    margin-bottom: 20px;
  }

  nav {
    margin-bottom: 20px;
  }

  .nav-link {
    padding: 10px;
  }
}
</style> 