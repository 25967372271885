<template>
  <div class="clients-content">
    <div v-if="loading">Loading...</div>
    <div v-else-if="error">Error: {{ error }}</div>
    <table v-else>
      <thead>
        <tr>
          <th>Company ID</th>
          <th>Email</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="client in clients" :key="client.uid" @click="showClientDetails(client)">
          <td>{{ client.uid }}</td>
          <td>{{ client.email }}</td>
          <td>
            <span 
              class="status-badge"
              :class="{ 'status-active': client.active }"
            >
              {{ client.active ? 'Active' : 'Inactive' }}
            </span>
          </td>
          <td>
            <button @click.stop="showClientDetails(client)">View Details</button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Client Details Modal -->
    <div v-if="selectedClient" class="modal" @click="closeModal">
      <div class="modal-content" @click.stop>
        <span class="close" @click="closeModal">&times;</span>
        <div class="modal-main">
          <!-- Company Header -->
          <div class="company-header">
            <h3>{{ selectedClient.company_name }}</h3>
            <div class="header-actions">
              <button 
                @click="openAddDepartmentsModal" 
                class="add-departments-btn"
              >
                <i class="fas fa-plus"></i>
                Add Departments
              </button>
              <button 
                @click="toggleActive" 
                class="active-status" 
                :class="{ active: selectedClient.active }"
              >
                {{ selectedClient.active ? 'Active' : 'Inactive' }}
              </button>
            </div>
          </div>

          <!-- Two Column Layout -->
          <div class="two-column-layout">
            <!-- Left Column: Departments Section -->
            <div class="column departments-section">
              <h4>Department Managers</h4>
              <div v-if="departments.length === 0" class="empty-state">
                <p>No departments have been set up yet.</p>
              </div>
              <div v-else class="departments-grid">
                <div v-for="dept in departments" 
                     :key="dept.name" 
                     class="department-card">
                  <div class="department-header">
                    <div class="department-icon">
                      <i :class="getDepartmentIcon(dept.name)"></i>
                    </div>
                    <h3>{{ dept.name }}</h3>
                  </div>

                  <div v-if="dept.manager" class="manager-section">
                    <div class="manager-info">
                      <i class="fas fa-user manager-icon"></i>
                      <div class="manager-details">
                        <span class="manager-name">{{ dept.manager }}</span>
                        <span class="manager-email">{{ dept.managerEmail }}</span>
                      </div>
                    </div>
                    <button class="change-manager-btn" @click="openManagerModal(dept)">
                      <i class="fas fa-pen"></i>
                      Change Manager
                    </button>
                  </div>
                  <div v-else class="no-manager">
                    <p>No manager assigned</p>
                    <button class="add-manager-btn" @click="openManagerModal(dept)">
                      <i class="fas fa-plus"></i>
                      Add Manager
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Right Column: Employees Section -->
            <div class="column employees-section">
              <h4>Department Employees</h4>
              <EmployeesContent 
                v-if="selectedClient"
                :key="selectedClient.uid"
                :is-admin-view="true"
                :client-uid="selectedClient.uid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Departments Modal -->
    <div v-if="showDepartmentsModal" 
         class="modal-overlay"
         @click="closeDepartmentsModal">
      <div class="modal-content departments-modal" @click.stop>
        <button class="close-modal" @click="closeDepartmentsModal">
          <i class="fas fa-times"></i>
        </button>
        <h2>Add Departments</h2>
        
        <div class="departments-grid">
          <div v-for="dept in availableDepartments" 
               :key="dept.id"
               :class="['department-card', { 'selected': selectedDepartments.includes(dept) }]"
               @click="toggleDepartment(dept)">
            <div class="department-icon">
              <i :class="dept.icon"></i>
            </div>
            <h3>{{ dept.name }}</h3>
            <p class="department-brief">{{ dept.brief }}</p>
          </div>
        </div>

        <div class="modal-actions">
          <button class="cancel-btn" @click="closeDepartmentsModal">Cancel</button>
          <button class="save-btn" 
                  @click="saveDepartments"
                  :disabled="selectedDepartments.length === 0">
            Save Departments
          </button>
        </div>
      </div>
    </div>

    <!-- Manager Modal -->
    <div v-if="showManagerModal" 
         class="modal-overlay"
         @click="closeManagerModal">
      <div class="modal-content" @click.stop>
        <button class="close-modal" @click="closeManagerModal">
          <i class="fas fa-times"></i>
        </button>
        <h2>{{ selectedDepartment?.manager ? 'Change' : 'Add' }} Manager for {{ selectedDepartment?.name }}</h2>
        
        <div class="form-group">
          <label>Manager Name</label>
          <input type="text" 
                 v-model="managerName" 
                 placeholder="Enter manager name">
        </div>
        <div class="form-group">
          <label>Manager Email</label>
          <input type="email" 
                 v-model="managerEmail" 
                 placeholder="Enter manager email">
        </div>

        <div class="modal-actions">
          <button class="cancel-btn" @click="closeManagerModal">Cancel</button>
          <button class="save-btn" 
                  @click="saveManager"
                  :disabled="!isFormValid">
            Save Manager
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBusinessPlan, updateDepartmentManager, fetchAdminUsers, updateUserActiveStatus, updateBusinessDepartments } from '@/services/api';
import EmployeesContent from '@/components/EmployeesContent.vue';

export default {
  name: 'ClientsContent',
  components: {
    EmployeesContent
  },
  data() {
    return {
      clients: [],
      loading: true,
      error: null,
      selectedClient: null,
      departments: [],
      showManagerModal: false,
      showDepartmentsModal: false,
      managerName: '',
      managerEmail: '',
      selectedDepartment: null,
      selectedDepartments: [],
      availableDepartments: [
        {
          id: 1,
          name: 'Sales',
          icon: 'fas fa-chart-line',
          brief: 'Optimize sales processes and increase conversion rates with AI'
        },
        {
          id: 2,
          name: 'Customer Service',
          icon: 'fas fa-headset',
          brief: 'Enhance customer support with AI-powered solutions'
        },
        {
          id: 3,
          name: 'Marketing',
          icon: 'fas fa-bullhorn',
          brief: 'Transform marketing with AI-driven insights and automation'
        },
        {
          id: 4,
          name: 'Operations',
          icon: 'fas fa-cogs',
          brief: 'Streamline operations with intelligent automation'
        },
        {
          id: 5,
          name: 'Human Resources',
          icon: 'fas fa-users',
          brief: 'Modernize HR processes with AI assistance'
        },
        {
          id: 6,
          name: 'Finance',
          icon: 'fas fa-calculator',
          brief: 'Enhance financial operations with AI insights'
        }
      ]
    };
  },
  methods: {
    async fetchClients() {
      try {
        this.loading = true;
        // Check if we have a token before making the request
        const token = localStorage.getItem('authToken');
        if (!token) {
          this.error = 'You must be logged in to view this content';
          return;
        }

        const response = await fetchAdminUsers();
        // console.log('All users:', response.users);
        if (response.success) {
          // Filter to only include business users
          this.clients = response.users.filter(user => user.type === 'business');
          // console.log('Business clients:', this.clients);
        } else {
          throw new Error(response.error || 'Failed to fetch users');
        }
      } catch (error) {
        console.error('Error fetching clients:', error);
        this.error = error.response?.data?.error || 'Failed to fetch clients';
        // If unauthorized, redirect to login
        if (error.response?.status === 401) {
          this.$router.push('/login');
        }
      } finally {
        this.loading = false;
      }
    },
    async showClientDetails(client) {
      // console.log('Selected client:', client);
      this.selectedClient = JSON.parse(JSON.stringify(client));
      await this.fetchDepartments();
    },
    closeModal() {
      this.selectedClient = null;
      this.departments = [];
    },
    async fetchDepartments() {
      try {
        if (this.selectedClient?.uid) {
          // console.log('Fetching business plan for UID:', this.selectedClient.uid);
          const response = await getBusinessPlan(this.selectedClient.uid);
          // console.log('Business Plan Response:', response);
          
          if (response.success && response.plan) {
            this.departments = response.plan;
          }
        }
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    },
    getDepartmentIcon(deptName) {
      switch (deptName) {
        case 'Marketing':
          return 'fas fa-bullhorn';
        case 'Sales':
          return 'fas fa-handshake';
        case 'IT':
          return 'fas fa-laptop-code';
        case 'HR':
          return 'fas fa-users';
        default:
          return 'fas fa-question-circle';
      }
    },
    openManagerModal(dept) {
      this.selectedDepartment = dept;
      this.managerName = dept.manager || '';
      this.managerEmail = dept.managerEmail || '';
      this.showManagerModal = true;
    },
    closeManagerModal() {
      this.showManagerModal = false;
      this.selectedDepartment = null;
      this.managerName = '';
      this.managerEmail = '';
    },
    openAddDepartmentsModal() {
      this.selectedDepartments = [...this.departments];
      this.showDepartmentsModal = true;
    },
    closeDepartmentsModal() {
      this.showDepartmentsModal = false;
      this.selectedDepartments = [];
    },
    toggleDepartment(dept) {
      const index = this.selectedDepartments.findIndex(d => d.id === dept.id);
      if (index === -1) {
        this.selectedDepartments.push(dept);
      } else {
        this.selectedDepartments.splice(index, 1);
      }
    },
    async saveDepartments() {
      try {
        const departmentsData = this.selectedDepartments.map(dept => ({
          id: dept.id,
          name: dept.name,
          employeeRange: 1, // Default to smallest range
          price: 0 // Price will be calculated on the backend
        }));

        const response = await updateBusinessDepartments(this.selectedClient.uid, departmentsData);
        
        if (response.success) {
          await this.fetchDepartments(); // Refresh the departments list
          this.closeDepartmentsModal();
        } else {
          throw new Error(response.error || 'Failed to update departments');
        }
      } catch (error) {
        console.error('Error saving departments:', error);
        alert('There was an error saving the departments. Please try again.');
      }
    },
    async saveManager() {
      if (!this.isFormValid) return;

      try {
        const response = await updateDepartmentManager(
          this.selectedClient.uid,
          this.selectedDepartment.name,
          this.managerName.trim(),
          this.managerEmail.trim()
        );

        if (response.success) {
          await this.fetchDepartments(); // Refresh the departments list
          this.closeManagerModal();
        } else {
          throw new Error(response.error || 'Failed to update manager');
        }
      } catch (error) {
        console.error('Error saving manager:', error);
        alert('There was an error saving the manager. Please try again.');
      }
    },
    async toggleActive() {
      try {
        const newActiveStatus = !this.selectedClient.active;
        const response = await updateUserActiveStatus(this.selectedClient.uid, newActiveStatus);
        
        if (response.success) {
          this.selectedClient.active = newActiveStatus;
          // Update the client in the list
          const clientIndex = this.clients.findIndex(c => c.uid === this.selectedClient.uid);
          if (clientIndex !== -1) {
            this.clients[clientIndex].active = newActiveStatus;
          }
          // Show success message
          alert('User status updated successfully');
        } else {
          throw new Error(response.error || 'Failed to update active status');
        }
      } catch (error) {
        console.error('Error updating user active status:', error);
        const errorMessage = error.response?.data?.error || 'Failed to update active status';
        alert(errorMessage);
        // If unauthorized, redirect to login
        if (error.response?.status === 401) {
          this.$router.push('/login');
        }
      }
    }
  },
  computed: {
    isFormValid() {
      return this.managerName.trim() !== '' && this.managerEmail.trim() !== '';
    }
  },
  mounted() {
    this.fetchClients();
  }
}
</script>

<style scoped>
.clients-content {
  margin-top: 20px;
  height: calc(100vh - 100px);
  overflow: hidden;
  position: relative;
  padding: 0 20px;
  margin-left: 200px; /* Account for sidebar width */
  width: calc(100% - 200px); /* Subtract sidebar width */
}

table {
  width: calc(100% - 40px); /* Account for padding */
  border-collapse: collapse;
  background-color: var(--primary-background-cream);
  margin: 0 20px;
}

thead {
  position: sticky;
  top: 0;
  background-color: var(--primary-background-cream);
  z-index: 1;
}

tbody {
  display: block;
  overflow-y: auto;
  height: calc(100vh - 200px);
}

thead, tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

th, td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

th {
  background-color: var(--primary-background-cream);
  font-weight: bold;
}

tr:hover {
  background-color: var(--accent-sunny-yellow);
  cursor: pointer;
}

.status-badge {
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 0.9em;
  font-weight: 500;
  background-color: #ff4444;
  color: white;
  display: inline-block;
}

.status-badge.status-active {
  background-color: #00C851;
}

.status-badge:not(.status-active) {
  background-color: #ff4444;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 200px; /* Account for sidebar width */
  top: 0;
  width: calc(100% - 200px); /* Subtract sidebar width */
  height: 100%;
  overflow: hidden;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: var(--primary-background-cream);
  margin: 2% auto;
  padding: 0;
  border: 1px solid #888;
  width: 95%;
  height: 95vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.modal-main {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.company-header {
  padding: 20px 40px;
  background: white;
  border-bottom: 1px solid #eee;
  flex-shrink: 0;
}

.two-column-layout {
  display: flex;
  gap: 2rem;
  padding: 20px 40px;
  height: calc(100% - 80px);
  overflow: hidden;
  background-color: var(--primary-background-cream);
}

.column {
  min-width: 0;
  overflow-y: auto;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 100%;
}

.departments-section {
  flex: 1;
  padding: 30px;
}

.employees-section {
  flex: 2;
  padding: 30px;
}

.close {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #666;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  z-index: 2;
  background: white;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.close:hover {
  background-color: #f5f5f5;
  color: #333;
}

.departments-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  padding: 0;
  margin-top: 20px;
}

.department-card {
  background: white;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  transition: all 0.2s ease;
  width: 100%;
}

.department-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.department-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.department-icon {
  margin-right: 10px;
}

.department-icon i {
  font-size: 1.5em;
  color: var(--accent-victory-green);
}

.manager-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.manager-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.manager-details {
  flex: 1;
  min-width: 0;
}

.manager-name {
  font-weight: bold;
  display: block;
}

.manager-email {
  word-break: break-all;
}

.change-manager-btn,
.add-manager-btn {
  width: 100%;
  padding: 8px;
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 5px;
}

.change-manager-btn {
  background-color: var(--accent-victory-green);
  color: white;
}

.add-manager-btn {
  background-color: var(--accent-victory-green);
  color: white;
}

.change-manager-btn:hover,
.add-manager-btn:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 200px; /* Account for sidebar width */
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.cancel-btn,
.save-btn {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.cancel-btn {
  background-color: #ff4444;
  color: white;
}

.save-btn {
  background-color: var(--accent-victory-green);
  color: white;
}

.save-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.empty-state {
  text-align: center;
  padding: 40px;
  background-color: #f8f8f8;
  border-radius: 8px;
  margin: 20px;
}

.header-actions {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.add-departments-btn {
  background-color: var(--accent-victory-green);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

.add-departments-btn:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

.departments-modal {
  width: 90%;
  max-width: 1000px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  left: 0; /* Reset left position since parent is already offset */
}

.department-brief {
  font-size: 0.9rem;
  color: #666;
  margin-top: 0.5rem;
}

.employees-section {
  margin-top: 2rem;
  padding: 0 20px;
}

.employees-section h4 {
  color: var(--accent-victory-green);
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.departments-section h4,
.employees-section h4 {
  margin: 0 0 1rem 0;
  padding: 0;
  color: var(--accent-victory-green);
  font-size: 1.5rem;
}
</style> 