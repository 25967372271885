<template>
  <div class="auth-container">
    <div class="auth-card">
      <div class="form-section">
        <transition name="fade" mode="out-in">
          <component :is="currentComponent" @switch-component="switchComponent" />
        </transition>
      </div>
      <div class="carousel-section">
        <AuthCarousel />
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from '@/components/LoginForm.vue'
import SignUpForm from '@/components/SignUpForm.vue'
import ForgotPasswordForm from '@/components/ForgotPasswordForm.vue'
import AuthCarousel from '@/components/AuthCarousel.vue'

export default {
  name: 'AuthView',
  components: {
    LoginForm,
    SignUpForm,
    ForgotPasswordForm,
    AuthCarousel
  },
  data() {
    return {
      currentComponent: 'LoginForm'
    }
  },
  created() {
    this.currentComponent = this.$route.query.action === 'signup' ? 'SignUpForm' : 'LoginForm';
  },
  watch: {
    '$route.query.action': {
      immediate: true,
      handler(newAction) {
        this.currentComponent = newAction === 'signup' ? 'SignUpForm' : 'LoginForm';
      }
    }
  },
  methods: {
    switchComponent(component) {
      this.currentComponent = component
    }
  }
}
</script>

<style scoped>
.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  background: linear-gradient(90deg, #fcf8f2, #79cce1);
  padding: 20px 0;
}

.auth-card {
  display: flex;
  background-color: white;
  border-radius: 20px;
  overflow: hidden;
  width: 80%;
  max-width: 1200px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  min-height: 600px;
}

.form-section {
  flex: 1;
  width: 50%;
  max-width: 50%;
  display: flex;
  align-items: center;
}

.carousel-section {
  flex: 1;
  width: 50%;
  max-width: 100%;
  /* max-height: 100%; */
  padding: 0;
  padding-left: 20px;
/* padding-bottom: 100px; */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 768px) {
  .auth-card {
    flex-direction: column;
    width: 90%;
  }

  .form-section,
  .carousel-section {
    width: 100%;
    max-width: 100%;
    padding-left: 0px;
  }
}
</style>
