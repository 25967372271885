import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import HowItWorksView from '../views/HowItWorksView.vue'
import PricingView from '../views/PricingView.vue'
import WhyVictoryView from '../views/WhyVictoryView.vue'
import ScheduleView from '../views/ScheduleView.vue'
import AuthView from '../views/AuthView.vue'
import DashboardView from '../views/DashboardView.vue'
import AdminDashboardView from '../views/AdminDashboardView.vue'
import BusinessDashboardView from '@/views/BusinessDashboardView.vue'
import ChatAssessment from '@/components/ChatAssessment.vue'
import AccessibilityStatementView from '@/views/AccessibilityStatementView.vue'
import PrivacyPolicyView from '@/views/PrivacyPolicyView.vue'
import TermsOfServiceView from '@/views/TermsOfServiceView.vue'
import CookiePreferencesView from '@/views/CookiePreferencesView.vue'
import ContactView from '@/views/ContactView.vue'
import FAQView from '@/views/FAQView.vue'
import MoneyBackGuaranteeView from '../views/MoneyBackGuaranteeView.vue'
import SwimLaneWrapper from '../views/SwimLaneWrapper.vue'
import store from '../store'
import DiscoveryCallContent from '@/components/DiscoveryCallContent.vue'
import StakeholdersContent from '@/components/StakeholdersContent.vue'
import ProposalContent from '@/components/ProposalContent.vue'
import ReportsContent from '@/components/ReportsContent.vue'
import BusinessDashboardContent from '@/components/BusinessDashboardContent.vue'
import AssessmentContent from '@/components/ExistingBusinessAssessmentResults.vue'
import MobileLoginPlaceholder from '@/components/MobileLoginPlaceholder.vue'
import SitemapView from '@/views/SitemapView.vue'
import ProjectsContent from '@/components/ProjectsContent.vue'
import SupportContent from '@/components/SupportContent.vue'
import VIPView from '@/views/VIPView.vue'
import Test from '@/components/Test.vue'
import ManagerView from '@/views/ManagerView.vue'
import GetStarted from '@/components/GetStarted.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/get-started',
    name: 'getStarted',
    component: GetStarted
  },
  {
    path: '/how-it-works',
    name: 'howItWorks',
    component: HowItWorksView
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: PricingView
  },
  {
    path: '/why-victory',
    name: 'whyVictory',
    component: WhyVictoryView
  },
  {
    path: '/schedule',
    name: 'Schedule',
    component: ScheduleView
  },
  {
    path: '/login',
    name: 'auth',
    component: AuthView,
    beforeEnter: (to, from, next) => {
      if (window.innerWidth <= 768) {
        next({ name: 'mobile-login' });
      } else {
        next();
      }
    }
  },
  {
    path: '/mobile-login',
    name: 'mobile-login',
    component: MobileLoginPlaceholder
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
    meta: { requiresAuth: true, allowedTypes: ['agency'] }
  },
  {
    path: '/admin-dashboard',
    name: 'adminDashboard',
    component: AdminDashboardView,
    meta: { requiresAuth: true, allowedTypes: ['admin'] }
  },
  {
    path: '/business-dashboard',
    name: 'business-dashboard',
    component: () => import('../views/BusinessDashboardView.vue'),
    meta: { hideHeader: true }
  },
  {
    path: '/admin-dashboard',
    name: 'admin-dashboard',
    component: () => import('../views/AdminDashboardView.vue'),
    meta: { hideHeader: true }
  },
  {
    path: '/assessment',
    name: 'assessment',
    redirect: '/get-started'
  },
  {
    path: '/accessibility',
    name: 'accessibility',
    component: AccessibilityStatementView
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: PrivacyPolicyView
  },
  {
    path: '/terms-of-service',
    name: 'termsOfService',
    component: TermsOfServiceView
  },
  {
    path: '/cookie-preferences',
    name: 'cookiePreferences',
    component: CookiePreferencesView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/faq',
    name: 'faq',
    component: FAQView
  },
  {
    path: '/money-back-guarantee',
    name: 'moneyBackGuarantee',
    component: MoneyBackGuaranteeView
  },
  {
    path: '/business/success-roadmap',
    name: 'BusinessSuccessRoadmap',
    component: BusinessDashboardContent
  },
  {
    path: '/business/initial-assessment',
    name: 'BusinessInitialAssessment',
    component: ChatAssessment,
    meta: { hideHeader: true }
  },
  {
    path: '/business/discovery-call',
    name: 'BusinessDiscoveryCall',
    component: DiscoveryCallContent
  },
  {
    path: '/business/stakeholders',
    name: 'BusinessStakeholders',
    component: StakeholdersContent
  },
  {
    path: '/business/proposal',
    name: 'BusinessProposal',
    component: ProposalContent
  },
  {
    path: '/business/reports',
    name: 'BusinessReports',
    component: ReportsContent
  },
  {
    path: '/business/projects',
    name: 'BusinessProjects',
    component: ProjectsContent
  },
  {
    path: '/business/support',
    name: 'BusinessSupport',
    component: SupportContent
  },
  {
    path: '/white-paper-2025',
    name: 'whitePaper',
    component: () => import('@/views/WhitePaperView.vue')
  },
  {
    path: '/sitemap',
    name: 'Sitemap',
    component: SitemapView
  },
  {
    path: '/vip',
    name: 'vip',
    component: VIPView
  },
  {
    path: '/swim',
    name: 'swim',
    component: SwimLaneWrapper
  },
  {
    path: '/google-test',
    name: 'test',
    component: Test
  },
  {
    path: '/manager-dashboard',
    name: 'manager-dashboard',
    component: ManagerView,
    meta: { 
      requiresAuth: true, 
      allowedTypes: ['manager'],
      hideHeader: true 
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // console.log('Route Change - Login Status:', store.state.loggedIn);
  // console.log('Navigating from:', from.path, 'to:', to.path);
  
  const userType = localStorage.getItem('userType');
  const userEmail = localStorage.getItem('userEmail');
  
  // Strict admin access control
  if (to.path === '/admin-dashboard') {
    if (userEmail === 'jayson@paglow.com') {
      next();
    } else {
      // console.log('Unauthorized access to admin dashboard, redirecting to business dashboard');
      next('/business-dashboard');
    }
    return;
  }
  
  // Manager access control
  if (to.path === '/manager-dashboard') {
    if (userType === 'manager') {
      next();
    } else {
      // console.log('Unauthorized access to manager dashboard');
      next('/login');
    }
    return;
  }
  
  // All other users go to business dashboard
  if (to.path === '/business-dashboard') {
    if (userEmail === 'jayson@paglow.com') {
      next('/admin-dashboard');
    } else if (userType === 'manager') {
      next('/manager-dashboard');
    } else {
      next();
    }
    return;
  }
  
  if (to.meta.hideHeader) {
    store.dispatch('setShowHeader', false);
  } else {
    store.dispatch('setShowHeader', true);
  }
  next();
});

export default router
