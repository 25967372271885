<template>
  <div class="chat-construction">
    <template v-if="hasAnyContent">
      <!-- Header Section -->
      <div class="report-section" v-if="hasBasicInfo">
        <div class="report-header">
          <h1>{{ companyName }}</h1>
          <div class="contact-info">
            <p><strong>Contact:</strong> {{ personName }}</p>
            <p><strong>Role:</strong> {{ personRole }}</p>
          </div>
          <div class="report-date">
            <p>{{ currentDate }}</p>
          </div>
        </div>
      </div>

      <!-- Business Challenges Section -->
      <div class="report-section" v-if="hasBusinessChallenges">
        <h2>Business Challenges</h2>
        <div class="challenge-summary">
          <h3>Primary Challenge</h3>
          <p>{{ primaryChallenge }}</p>
          <p class="duration" v-if="challengeDuration">
            <em>Duration: {{ challengeDuration }}</em>
          </p>
        </div>
      </div>

      <!-- Goals Section -->
      <div class="report-section" v-if="hasGoals">
        <h2>Strategic Goals (3-6 Months)</h2>
        <div class="goals-summary">
          <ol class="goals-list">
            <li v-for="(goal, index) in goalsList" :key="index">{{ goal }}</li>
          </ol>
        </div>
        <div class="previous-attempts" v-if="hasPreviousAttempts">
          <h3>Previous Solutions Attempted</h3>
          <p>{{ previousAttempts }}</p>
        </div>
      </div>

      <!-- Department Impact Section -->
      <div class="report-section" v-if="hasDepartmentInfo">
        <h2>Departmental Impact Analysis</h2>
        <div class="department-analysis">
          <div class="affected-departments" v-if="affectedDepartments">
            <h3>Affected Departments</h3>
            <p>{{ affectedDepartments }}</p>
          </div>
          <div class="priority-departments" v-if="benefitingDepartments">
            <h3>Priority Focus Areas</h3>
            <pre class="formatted-text">{{ benefitingDepartments }}</pre>
          </div>
        </div>
      </div>
    </template>
    
    <div v-else class="empty-state">
      <svg class="notepad-icon" width="64" height="64" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7 7H17" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
        <path d="M7 11H17" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
        <path d="M7 15H13" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
      </svg>
      <span class="empty-state-text">AI Notes</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ChatConstruction',
  computed: {
    ...mapGetters('chatStore', ['getStepAnswer', 'currentStep', 'currentSubStep']),
    
    // Basic Info Computed Properties
    hasBasicInfo() {
      const nameAnswer = this.getStepAnswer(1, 8)
      const roleAnswer = this.getStepAnswer(1, 9)
      // console.log('Name answer:', nameAnswer)
      // console.log('Role answer:', roleAnswer)
      return nameAnswer && roleAnswer
    },
    companyName() {
      const nameAnswer = this.getStepAnswer(1, 8)
      return nameAnswer?.company_name || ''
    },
    personName() {
      const nameAnswer = this.getStepAnswer(1, 8)
      return nameAnswer ? `${nameAnswer.first_name} ${nameAnswer.last_name}` : ''
    },
    personRole() {
      const roleAnswer = this.getStepAnswer(1, 9)
      return roleAnswer?.text || ''
    },
    currentDate() {
      return new Date().toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    },

    // Business Challenges Computed Properties
    hasBusinessChallenges() {
      return this.getStepAnswer(2, 1)
    },
    primaryChallenge() {
      const challengeInfo = this.getStepAnswer(2, 1)
      return challengeInfo?.text || ''
    },
    challengeDuration() {
      const durationInfo = this.getStepAnswer(2, 2)
      // Handle both formats - either direct text or duration field
      if (!durationInfo) return ''
      return durationInfo.duration || durationInfo.text || ''
    },

    // Goals Computed Properties
    hasGoals() {
      const goalsAnswer = this.getStepAnswer(2, 3)
      return goalsAnswer && goalsAnswer.goals && goalsAnswer.goals.length > 0
    },
    goalsList() {
      const goalsAnswer = this.getStepAnswer(2, 3)
      return goalsAnswer?.goals || []
    },
    hasPreviousAttempts() {
      const attemptsAnswer = this.getStepAnswer(2, 4)
      return attemptsAnswer && attemptsAnswer.attempts_summary
    },
    previousAttempts() {
      const attemptsAnswer = this.getStepAnswer(2, 4)
      return attemptsAnswer?.attempts_summary || ''
    },

    // Department Info Computed Properties
    hasDepartmentInfo() {
      return this.hasAffectedDepartments || this.hasBenefitingDepartments
    },
    hasAffectedDepartments() {
      const deptAnswer = this.getStepAnswer(3, 1)
      return deptAnswer && deptAnswer.departments && deptAnswer.impact_summary
    },
    hasBenefitingDepartments() {
      const deptAnswer = this.getStepAnswer(3, 2)
      return deptAnswer && deptAnswer.desired_outcome
    },
    affectedDepartments() {
      const deptAnswer = this.getStepAnswer(3, 1)
      if (!deptAnswer) return ''
      return `${deptAnswer.departments.join(', ')}. ${deptAnswer.impact_summary}`
    },
    benefitingDepartments() {
      const deptAnswer = this.getStepAnswer(3, 2)
      if (!deptAnswer) return ''
      
      const outcome = deptAnswer.desired_outcome
      if (typeof outcome === 'string') return outcome
      
      // Handle structured response
      if (typeof outcome === 'object') {
        // Extract metrics into bullet points if they exist
        const metricsText = outcome.measurable_outcomes || ''
        const metrics = metricsText
          .split(/\d+\.\s+/) // Split on numbered lists
          .filter(Boolean) // Remove empty strings
          .map((metric, index) => `  ${index + 1}. ${metric.trim()}`)
          .join('\n')

        const sections = []
        sections.push('Priority Focus Areas')
        sections.push(outcome.specific_improvements)
        
        if (outcome.impact_on_challenges) {
          sections.push('\nImpact Analysis')
          sections.push(outcome.impact_on_challenges)
        }
        
        if (metrics) {
          sections.push('\nKey Metrics')
          sections.push(metrics)
        }
        
        if (outcome.alignment_with_goals) {
          sections.push('\nStrategic Alignment')
          sections.push(outcome.alignment_with_goals)
        }
        
        return sections.join('\n')
      }
      
      return ''
    },

    hasAnyContent() {
      return this.hasBasicInfo || this.hasBusinessChallenges || this.hasGoals || this.hasDepartmentInfo
    }
  },
  methods: {
    scrollToBottom() {
      this.$nextTick(() => {
        this.$el.scrollTop = this.$el.scrollHeight
      })
    }
  },
  watch: {
    hasBasicInfo() {
      this.scrollToBottom()
    },
    hasBusinessChallenges() {
      this.scrollToBottom()
    },
    hasGoals() {
      this.scrollToBottom()
    },
    hasPreviousAttempts() {
      this.scrollToBottom()
    },
    affectedDepartments() {
      this.scrollToBottom()
    },
    benefitingDepartments() {
      this.scrollToBottom()
    }
  }
}
</script>

<style scoped>
.chat-construction {
  height: 100%;
  padding: 2rem;
  background-color: white;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.03);
  border: 1px solid #f0f0f0;
  margin: 0.5rem;
  border-radius: 8px;
}

.report-section {
  margin-bottom: 2.5rem;
  position: relative;
}

.report-section:not(:last-child)::after {
  content: '';
  position: absolute;
  bottom: -1.25rem;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(to right, transparent, var(--accent-victory-green), transparent);
}

.report-header {
  margin-bottom: 2rem;
}

h1 {
  color: var(--accent-victory-green);
  font-size: 2rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

h2 {
  color: var(--accent-victory-green);
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
  font-weight: 500;
}

h3 {
  color: var(--secondary-background-sky-blue);
  font-size: 1.1rem;
  margin-bottom: 1rem;
  font-weight: 500;
}

.contact-info {
  margin-bottom: 1.25rem;
  line-height: 1.6;
}

.contact-info p {
  margin: 0.5rem 0;
  color: #4A4A4A;
}

.report-date {
  color: #666;
  font-style: italic;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #eee;
}

.challenge-summary, .goals-summary, .previous-attempts {
  background-color: #F8F9FA;
  padding: 1.5rem;
  border-radius: 0.5rem;
  margin: 1rem 0;
  border-left: 3px solid var(--accent-victory-green);
}

.duration {
  color: #666;
  margin-top: 1rem;
  font-style: italic;
  padding-top: 0.5rem;
  border-top: 1px dashed #ddd;
}

.department-analysis {
  display: grid;
  gap: 1.5rem;
}

.affected-departments, .priority-departments {
  background-color: #F8F9FA;
  padding: 1.5rem;
  border-radius: 0.5rem;
  border-left: 3px solid var(--secondary-background-sky-blue);
}

p {
  line-height: 1.6;
  color: #4A4A4A;
  margin: 0.75rem 0;
  font-size: 0.95rem;
}

.empty-state {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #CCCCCC;
}

.notepad-icon {
  margin-bottom: 1rem;
  color: #CCCCCC;
}

.empty-state-text {
  font-size: 1.25rem;
  font-weight: 500;
  color: #CCCCCC;
}

.goals-list {
  margin: 0;
  padding-left: 1.5rem;
}

.goals-list li {
  margin-bottom: 0.75rem;
  color: #4A4A4A;
  line-height: 1.6;
  font-size: 0.95rem;
}

.goals-list li:last-child {
  margin-bottom: 0;
}

.formatted-text {
  white-space: pre-wrap;
  font-family: inherit;
  margin: 0;
  padding: 0;
  font-size: inherit;
  line-height: 1.6;
  color: inherit;
  background: none;
}
</style> 