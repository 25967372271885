<template>
  <div class="manager-view">
    <div v-if="loading" class="loading-state">
      <i class="fas fa-spinner fa-spin"></i>
      <p>Loading...</p>
    </div>
    <template v-else>
      <ManagerSidebar 
        :currentComponent="currentComponent"
        @navigate="handleNavigation"
      />
      <div class="content-wrapper">
        <transition name="fade" mode="out-in">
          <component 
            :is="currentComponent"
            @navigate="handleNavigation"
            :key="currentComponent"
          />
        </transition>
      </div>
    </template>
  </div>
</template>

<script>
import ManagerSidebar from '@/components/ManagerSidebar.vue'
import EmployeesContent from '@/components/EmployeesContent.vue'
import DiscoveryCallContent from '@/components/DiscoveryCallContent.vue'
import SupportContent from '@/components/SupportContent.vue'
import { API_BASE_URL } from '@/services/api'
import axios from 'axios'

export default {
  name: 'ManagerView',
  components: {
    ManagerSidebar,
    EmployeesContent,
    DiscoveryCallContent,
    SupportContent
  },
  data() {
    return {
      currentComponent: 'EmployeesContent',
      loading: true
    }
  },
  methods: {
    handleNavigation(component) {
      this.currentComponent = component
    },
    async fetchManagerData() {
      try {
        // console.log('[ManagerView] Starting fetchManagerData')
        const userEmail = localStorage.getItem('userEmail')
        // console.log('[ManagerView] User email from localStorage:', userEmail)
        
        if (!userEmail) {
          // console.log('[ManagerView] No user email found in localStorage')
          throw new Error('No user email found')
        }

        const authToken = localStorage.getItem('authToken')
        // console.log('[ManagerView] Auth token exists:', !!authToken)

        if (!authToken) {
          throw new Error('No auth token found')
        }

        const uid = localStorage.getItem('uid')
        // console.log('[ManagerView] User UID from localStorage:', uid)

        if (!uid) {
          throw new Error('No user UID found')
        }
        
        // Call the API to get the manager's user document using UID
        const apiUrl = `${API_BASE_URL}/api/manager/user/${uid}`
        // console.log('[ManagerView] Making API request to:', apiUrl)
        
        const response = await axios.get(apiUrl, {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            'X-User-Email': userEmail
          }
        })

        // console.log('[ManagerView] API Response status:', response.status)
        // console.log('[ManagerView] API Response data:', response.data)

        if (response.data.success && response.data.user && response.data.user.company_uid) {
          // console.log('[ManagerView] Found company_uid:', response.data.user.company_uid)
          // Store the company_uid in localStorage
          localStorage.setItem('company_uid', response.data.user.company_uid)
          return true
        } else {
          // console.log('[ManagerView] Invalid or incomplete manager data:', response.data)
          throw new Error('Manager data is incomplete')
        }
      } catch (error) {
        console.error('[ManagerView] Error in fetchManagerData:', error.response ? {
          status: error.response.status,
          data: error.response.data,
          headers: error.response.headers
        } : error.message)
        return false
      }
    }
  },
  async created() {
    // console.log('[ManagerView] Component created')
    // Check if user is authenticated and is a manager
    const userType = localStorage.getItem('userType')
    // console.log('[ManagerView] User type from localStorage:', userType)

    if (!userType || userType !== 'manager') {
      // console.log('[ManagerView] Invalid user type, redirecting to login')
      this.$router.push('/login')
      return
    }

    // Get the manager's company_uid from localStorage or fetch it
    const companyUid = localStorage.getItem('company_uid')
    // console.log('[ManagerView] Initial company_uid from localStorage:', companyUid)

    if (!companyUid) {
      // console.log('[ManagerView] No company_uid found, fetching from API')
      const success = await this.fetchManagerData()
      // console.log('[ManagerView] fetchManagerData result:', success)
      
      if (!success) {
        // console.log('[ManagerView] Failed to fetch manager data, redirecting to login')
        this.$router.push('/login')
        return
      }
    }

    // console.log('[ManagerView] Setup complete, setting loading to false')
    this.loading = false
  }
}
</script>

<style scoped>
.manager-view {
  display: flex;
  min-height: 100vh;
  background-color: var(--primary-background-color);
}

.content-wrapper {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  background-color: var(--primary-background-color);
}

.loading-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  font-size: 1.2rem;
  color: var(--accent-victory-green);
}

.loading-state i {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 768px) {
  .manager-view {
    flex-direction: column;
  }

  .content-wrapper {
    padding: 10px;
  }
}
</style> 